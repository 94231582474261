<template>
    <main class="homepage">
        <div class="homepage__head">
            <div class="homepage__head-content">
                <h1 class="homepage__heading" v-if="!isMobile">{{ $t("links.main") }}</h1>

                <SearchBar class="homepage__searchbar"/>
            </div>
        </div>
        <div class="page-content">
            <h1 class="homepage__heading" v-if="isMobile">{{ $t("links.main") }}</h1>
            <!--            <BannersCarousel class="homepage__banners-carousel"/>-->

            <News class="homepage__news"
                  v-if="permissions['news.view']"
            />
        </div>
    </main>
</template>

<script>
import SearchBar from "@/components/blocks/SearchBar.vue";
// import BannersCarousel from "./home/BannersCarousel.vue";
import News from "./home/News.vue";

export default {
    components: {
        // BannersCarousel,
        SearchBar,
        News
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/base";

.homepage {
  &__banners-carousel {
    margin-bottom: rem(30px);

    @include mobile {
      margin: rem(0 -18px);
      margin-bottom: rem(8px);
      padding-left: rem(18px);
    }
  }

  &__heading {
    text-transform: uppercase;

    @include mobile {
      margin-bottom: rem(10px);
    }
  }

  &__head {
    width: 100%;
    padding: rem(40px 0);
    @include contentPaddings;
    background-color: #d3dde6;

    @include mobile {
      padding: rem(18px);
    }
  }

  &__head-content {
    width: 100%;
    //max-width: 1170px;
    display: flex;
    align-items: center;
  }

  &__searchbar {
    margin-left: auto;
    width: 66%;

    @include mobile {
      width: 100%;
    }
  }
}
</style>