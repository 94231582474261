<template>
    <section class="news">
        <div class="news__wrap">
            <h2 class="news__heading">{{ $t("links.news")}}</h2>

            <div class="news__filters filters" style="display: none;">
                <div class="filters__form form--dark">
                    <button
                            class="filters__btn"
                            @click="filter = 'all'"
                            :class="filter == 'all' ? 'filters__btn--active' : null"
                    >
                        {{ $t("common.all")}}
                    </button>

                    <div class="filters__group">
                        <Select
                                label=""
                                :preselect="true"
                                :visibleLabel="false"
                                :options="years"
                        />
                    </div>
                </div>
            </div>

            <ul class="news__list">
                <NewsItem class="news__news-item" v-for="(item, key) in newsPosts" :key="key" :item="item"/>
            </ul>
        </div>
    </section>
</template>

<script>
import Select from "@/components/elements/form/Select.vue";
import NewsItem from './NewsItem.vue'

export default {
    components: {
        Select,
        NewsItem
    },
    data() {
        return {
            filter: "All",
            years: [
                {
                    label: "За 2019 год",
                },
                {
                    label: "За 2020 год",
                },
            ],
            newsPosts: [],
        };
    },
    watch: {
        year(val) {
            this.filter = val;
        },
    },

    mounted() {

        fetch(this.location + "/pages/view?orderBy=date&order=DESC")
            .then(json => json.json())
            .then(data => {
                const arr = [];

                for (let news of data) {
                    const comments = [];
                    if (news.comments && Array.isArray(news.comments) && news.is_comment !== 0) {
                        news.comments.forEach(comment => {
                            comments.push({
                                author: comment.username,
                                content: comment.text,
                                dateCode: comment.date,
                                date: comment.date,
                                id: comment.id
                            });
                        });
                    }
                    arr.push({
                        attachments: news.attachments,
                        title: news.name,
                        tag: "announcement",
                        content: news.text,
                        dateCode: news.date.split(" ")[0],
                        date: news.date.split(" ")[0],
                        likes: 15,
                        id: news.id,
                        comments,
                        isComment: news.is_comment,
                        polls: news.polls,
                        isPoll: Boolean(news.polls),
                        isVote: news.isVote
                    });
                }

                this.newsPosts = arr;
            });

    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base";
@import "@/assets/scss/form";

.news {
  &__heading {
    font-weight: 500;
    color: #25305d;
    margin-bottom: rem(36px);

    @include mobile {
      margin-bottom: rem(10px);
    }
  }

  &__filters {
    margin-bottom: rem(30px);

    @include mobile {
      margin-bottom: rem(15px);
    }
  }

  &__list {
    @include unlist;
  }

  &__news-item {
    background-color: #fff;
    border-radius: 7px;
    border: solid 1px #dfdfdf;
    padding: rem(24px 30px);
    margin-bottom: rem(26px);
  }
}

.filters {
  &__form {
    display: flex;
    align-items: center;

    &::v-deep {
      .form__group {
        margin-bottom: 0;
      }
    }
  }

  &__group {
    width: 20%;
    max-width: 220px;

    @include mobile {
      width: 75%;
      max-width: 240px;
    }
  }

  &__btn {
    color: #25305d;
    padding: rem(10px 20px);
    margin-right: rem(52px);
    border-radius: 7px;
    display: block;
    background-color: #ffffff;
    border: 1px solid #d3dde6;

    &--active {
      border: solid 1px #017aff;
    }

    @include mobile {
      margin-right: rem(10px);
      padding: rem(10px 28px);
      font-size: rem(14px);
    }
  }
}
</style>