<template>
    <div id="app">
        <notifications position="bottom right"/>

        <div class="preloader" :class="{opened: isOpenPreloader}">
            <div v-if="isPercentage" class="preloader__progress">
                <div class="preloader__progress--bar"
                     :style="{
                        width: width,
                        background: background
                     }"
                ></div>
                <div class="preloader__progress--percent">{{ $store.state.preloaderPercent }}%</div>
            </div>
            <svg viewBox="-2000 -1000 4000 2000" v-else>
                <path id="inf" d="M354-354A500 500 0 1 1 354 354L-354-354A500 500 0 1 0-354 354z"></path>
                <use xlink:href="#inf" stroke-dasharray="1570 5143" stroke-dashoffset="6713px"></use>
            </svg>
        </div>

        <div class="table__modal modal" v-if="$store.state.confirm">
            <div class="modal__wrap modal__wrap--heading-center">
                <div class="modal__head">
                    <div class="modal__title">{{ $t("modal.confirm") }}</div>
                </div>
                <div class="modal__foot">
                    <button class="modal__btn modal__btn--green" @click.prevent="completeConfirm(true);">
                        {{ $t("modal.yes") }}
                    </button>
                    <button class="modal__btn modal__btn--red" @click.prevent="completeConfirm(false);">
                        {{ $t("modal.no") }}
                    </button>
                </div>
            </div>
        </div>


        <div class="wrap" v-if="!isAuth && !isReg && !is404 && !is403">
            <Sidebar :notifications="notifications"/>
            <div class="content" :class="{'sidebar-show': isSidebarShow}">
                <Header :notifications="notifications"/>
                <router-view></router-view>
            </div>
            <Bottombar v-if="isTablet"/>
        </div>
        <router-view v-else></router-view>
    </div>
</template>

<script>
import Header from "@/components/layout/header/Header";
import Sidebar from "@/components/layout/sidebar/Sidebar";
import Bottombar from "@/components/layout/Bottombar";

export default {
    components: {
        Header,
        Sidebar,
        Bottombar,
    },
    computed: {
        isAuth() {
            return this.$route.path == "/auth";
        },
        isReg() {
            return this.$route.path == "/reg";
        },
        is404() {
            return this.$route.path == "/404";
        },
        is403() {
            return this.$route.path == "/403";
        },

        isOpenPreloader() {
            return this.$store.state.preloader;
        },

        isPercentage() {
            return this.$store.state.isPercentage;
        },

        isSidebarShow() {
            return this.$store.state.showSidebar;
        },

        width() {
            return this.$store.state.preloaderPercent + "%";
        },

        background() {
            const count = Math.round(255 * this.$store.state.preloaderPercent / 100);
            const rgb = [255 - count, count, 0];

            return `rgb(${rgb.join(",")})`;
        }
    },

    data() {
        return {
            notifications: {}
        }
    },

    methods: {
        completeConfirm(result) {
            if (!this.$store.state.confirmPromise) {
                return;
            }

            this.$store.commit("setConfirm", false);
            this.$store.state.confirmPromise.complete(result);
        }
    },

    created() {
        window.LOCATION_URL = this.location;
    },

    mounted() {

        if ((!this.getCookie("id") || !this.getCookie("hash")) && location.pathname !== "/auth") {
            this.$router.push("/auth");
            return;
        } else if (this.getCookie("id") && this.getCookie("hash")) {
            if (location.pathname === "/auth") {
                location.href = "/";
                return;
            } else {
                fetch(this.location + "/profile/check")
                    .then(data => data.json())
                    .then(data => {
                        if (data.status === "error") {
                            this.deleteCookie("id");
                            this.deleteCookie("hash");
                            this.$router.push("/auth");
                        }
                    });

            }
        }

        const func = () => {
            fetch(this.location + "/users/notifications/index")
                .then(data => data.json())
                .then(data => {
                    this.notifications = data.data;
                });

            return func;
        };
        setInterval(func(), 5 * 60 * 1000);
    }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap");
@import "~modern-normalize";
@import "./assets/scss/grid";
@import "./assets/scss/base";
@import "./assets/scss/reset";
@import "./assets/scss/default";
@import "./assets/scss/typography";
@import "./assets/scss/common";
@import "./assets/scss/profile";
@import "./assets/scss/settings-page";
@import "./assets/scss/subcontent";
@import "./assets/scss/modals";
@import "./assets/scss/form";
@import "./assets/scss/table";
@import "./assets/scss/setting";
@import "./assets/scss/content-filters";
@import "./assets/scss/edit-page";
@import "./assets/scss/group-profile";
@import "./assets/scss/legend";
@import "./assets/scss/graph";
@import "./assets/scss/schedule";
@import "./assets/scss/transfer";
@import "./assets/scss/history-filters";

.vue-notification {
  font-size: 20px !important;
  padding: 15px !important;
  margin: 0 15px 15px !important;
}

.vue-notification-group {
  z-index: 50000 !important;
}

.preloader {
  transition: visibility 250ms, opacity 250ms;
  position: fixed;
  z-index: 20000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;

  visibility: hidden;
  opacity: 0;

  &__progress {
    width: min(300px, 50%);
    height: 50px;
    border: solid 1px black;
    border-radius: 5px;
    position: relative;

    &--bar {
      height: 100%;
    }

    &--percent {
      color: white;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.preloader.opened {
  opacity: 1;
  visibility: visible;
}

.preloader svg {
  max-width: 100vw;
  max-height: 250px;
  border-radius: 3px;
  fill: none;
  stroke: rgba(34, 34, 34, 0.5);
  stroke-linecap: round;
  stroke-width: 8%
}

.preloader use {
  stroke: #fff;
  animation: a 2s linear infinite
}

@keyframes a {
  to {
    stroke-dashoffset: 0px
  }
}
</style>
