<template>
    <div class="form__group">
        <label
                class="form__label"
                :for="uid"
                :class="visibleLabel ? 'form__label--visible' : null"
        >
            {{ label }}
        </label>
        <input
                ref="input"
                :type="type"
                class="form__control"
                :class="time && 'form__control--time'"
                :id="uid"
                :placeholder="computedPlaceholder"
                :disabled="disabled"
                :value="value"
                :readonly="readonly"
                :required="required"
                :min="min"
                @input="$emit('input', $event.target.value)"
                @change="$emit('change', $event.target.value)"
                @click="$emit('click', $event)"
                @keyup="$emit('keyup', $event)"
                @keydown="$emit('keydown', $event)"
        />
    </div>
</template>

<script>
import IMask from "imask";

export default {
    props: {
        readonly: {
            required: false,
            default: false,
        },
        required: {
            required: false,
            default: false
        },
        label: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "text",
        },
        visibleLabel: {
            type: Boolean,
            default: true,
        },
        password: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: "",
        },
        time: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        min: {
            type: Number,
            default: 0
        }
    },
    mounted() {
        if (this.type === "tel") {
            IMask(this.$refs.input, {
                mask: "+{7} (000) 000-00-00",
            });
        } else if (this.type === 'date_text') {
            IMask(this.$refs.input, {
                mask: "0000-00-00"
            });
        } else if (this.type === 'worktime') {
            IMask(this.$refs.input, {
                mask: "00:00-00:00"
            });
        } else if (this.type === 'time') {
            IMask(this.$refs.input, {
                mask: "00:00"
            });
        }
    },
    computed: {
        uid() {
            return this._uid;
        },

        computedPlaceholder() {
            if (this.placeholder) {
                return this.placeholder;
            }

            if (this.type === 'tel') {
                return '+7 (___) ___-__-__';
            }
            if (this.type === 'date_text') {
                return 'уууу-мм-дд';
            }
            if (this.type === 'worktime') {
                return '09:00-19:00';
            }

            return this.label;
        }
    },
};
</script>
