import {objectToSearchParams} from "@/helpers/Request";

export class Setting {

    static KEYS = {
        EMPLOYEE_STATUS: 'employee_status',
        DEALING_CANCEL_REASONS: 'dealig_cancel_reasons',
        LANGUAGES: 'languages',
        LEVELS: 'levels_learning',
        DISCOUNT_REASON: 'discount_reason',
        STUDENT_CATEGORY: 'category_students',
        GROUPS_CAPACITY: 'groups_capacity',
        LESSON_FIELDS_DISPLAY: 'lesson_fields_display',
        ATTENDANCE_LIST: 'attendance_type',
        RATE_LIST: 'progress_student',
        GROUP_TYPE: 'type_groups',
        AUTH: 'authorization',
        LESSON_DURATIONS: 'etalon_lesson',
        LESSON_TYPES: 'lesson_type',
        LESSON_CATEGORY: 'lesson_category',
        EXPENSES_CATEGORY: 'expenses_categories',
        BANNERS: 'banners_index',
        CERTIFICATE_TITLE: 'certificate_title',
        PAYMENTS_SETTINGS: 'payments_settings',
        REFUSALS: 'refusal'
    };

    static get(params = {}) {
        const defaultParams = {
            id: [], key: []
        };
        params = {...defaultParams, ...params};

        const searchParams = objectToSearchParams(params);

        return fetch(window.LOCATION_URL + "/settings/index?" + searchParams.toString())
            .then(data => data.json())
            .then(data => data.data);
    }

    static getData(key, params = {}, addAll = false) {
        const defaultParams = {
            limit: -1,
            page: 1
        };
        params = {...defaultParams, ...params};
        params.key = key;

        const searchParams = objectToSearchParams(params);

        return fetch(window.LOCATION_URL + "/settings/values?" + searchParams.toString())
            .then(data => data.json())
            .then(data => data.data)
            .then(list => {
                if (addAll) {
                    list.data.unshift({
                        id: 0,
                        value: "Все"
                    });
                }

                return list;
            });
    }

    /**
     *
     * @param key
     * @param formData {FormData}
     * @return Promise<Object>
     */
    static saveValues(key, formData) {
        formData.set("key", key);

        return fetch(window.LOCATION_URL + "/settings/values/save", {
            method: "POST", body: formData
        })
            .then(data => data.json())
            .then(data => data.data);
    }

    static getCapacity(groupId) {
        return Setting.getData(Setting.KEYS.GROUPS_CAPACITY)
            .then(list => parseInt(list.data[groupId]));
    }

    /**
     *
     * @return {Promise<{min: number, sms: boolean}>}
     */
    static getAuthSettings() {
        return Setting
            .getData(Setting.KEYS.AUTH)
            .then(list => {
                const min = list.data.min;
                const sms = Boolean(list.data.isSMS);

                return {min, sms};
            });
    }

    static getIni(ini) {
        const params = {
            ini
        };
        const searchParams = objectToSearchParams(params);
        return fetch(window.LOCATION_URL + "/config/ini?" + searchParams.toString())
            .then(data => data.json())
            .then(data => data.data);
    }

}