import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vue2TouchEvents from 'vue2-touch-events'
import './assets/tailwind.css'
import {ConfirmPromise} from "@/helpers/ConfirmPromise";
import VTooltip from 'v-tooltip'
import VueI18n from 'vue-i18n';
import messages from "@/translation";
import Notifications from 'vue-notification'
import {Cookie} from "@/helpers/Cookie";

Vue.config.productionTip = false


Vue.use(Vue2TouchEvents);
Vue.use(VTooltip);
Vue.use(VueI18n);
Vue.use(Notifications);

/**
 *
 * @param oldArray {Array}
 * @returns {{deleted: *[], added: *[]}}
 */
Array.prototype.compare = function (oldArray) {
    const added = this.filter(el => !oldArray.includes(el));
    const deleted = oldArray.filter(el => !this.includes(el));

    return {
        added: Array.from(new Set(added)),
        deleted: Array.from(new Set(deleted))
    };
}

Number.prototype.between = function (from, to) {
    return from < this && this < to;
}

let locale = localStorage.getItem("lang") || "ru";
if (!["ru", "en"].includes(locale)) {
    locale = "ru";
}
Vue.mixin({
    computed: {
        location() {
            return process.env.VUE_APP_API_HOST || (location.protocol + "//" + location.hostname);
        },

        yearsList() {
            const year = new window.Date().getFullYear();
            const arr = [];
            for (let i = 2015; i <= year; i++) {
                arr.push({
                    label: String(i),
                    id: i
                });
            }

            arr.reverse();
            arr.unshift({
                label: messages[locale].common.all,
                id: 0
            });

            return arr;
        },

        permissions() {
            return this.$store.state.permissions;
        },

        employeeTypes() {
            const employees = [
                {label: messages[locale].common.all, id: 0},
                {label: messages[locale].links.admins, id: 2},
                {label: messages[locale].links.directors, id: 9},
                {label: messages[locale].links.managers, id: 11},
                {label: messages[locale].links.methodists, id: 10},
                {label: messages[locale].links.teachers, id: 12}
            ];
            this.$store.state.roles.forEach(role => {
                if (!role.defaultId) {
                    return;
                }

                const find = employees.find(el => el.id === role.defaultId);
                if (!find) return;
                find.label = role.label;
            });

            return employees;
        }
    },

    data() {
        return {
            priorities: [
                {label: messages[locale].common.none, value: 0, color: "#b9b9b9"},
                {label: messages[locale].priorities.low, value: 1, color: "#00FF00"},
                {label: messages[locale].priorities.middle, value: 2, color: "#ff9900"},
                {label: messages[locale].priorities.high, value: 2, color: "#FF0000"}],
            extensions: {
                excel: "xlsx",
                doc: "docx",
                txt: "txt",
                pdf: "pdf"
            },
            rowsLimits: [
                {label: "10", value: 10},
                {label: "25", value: 25},
                {label: "50", value: 50},
                {label: "100", value: 100},
                {label: messages[locale].common.all, value: -1},
            ],
            inputsForValidate: [],
            validatePrefix: "",
            groupTypes: [
                {label: messages[locale].common.all, id: 0},
                {label: messages[locale].links.groups, id: 1},
                {label: messages[locale].links.minigroups, id: 7},
                {label: messages[locale].links.individuals, id: 2},
                {label: messages[locale].links.corp, id: 3},
                {label: messages[locale].links.kindergroups, id: 6},
                {label: messages[locale].links.home, id: 5},
                {label: messages[locale].links.corpHome, id: 4},
                {label: messages[locale].links.consHours, id: 8}
            ],

            defaultImage: '/img/profile.jpg',

            /**
             * @type {Array}
             */
            monthsList: [
                {label: messages[locale].common.all, id: -1},
                {label: messages[locale].calendar.january, id: 0},
                {label: messages[locale].calendar.february, id: 1},
                {label: messages[locale].calendar.march, id: 2},
                {label: messages[locale].calendar.april, id: 3},
                {label: messages[locale].calendar.may, id: 4},
                {label: messages[locale].calendar.june, id: 5},
                {label: messages[locale].calendar.july, id: 6},
                {label: messages[locale].calendar.august, id: 7},
                {label: messages[locale].calendar.september, id: 8},
                {label: messages[locale].calendar.october, id: 9},
                {label: messages[locale].calendar.november, id: 10},
                {label: messages[locale].calendar.december, id: 11},
            ],
            weekDays: [
                {label: messages[locale].calendar.mondayShort, id: 1},
                {label: messages[locale].calendar.tuesdayShort, id: 2},
                {label: messages[locale].calendar.wednesdayShort, id: 3},
                {label: messages[locale].calendar.thursdayShort, id: 4},
                {label: messages[locale].calendar.fridayShort, id: 5},
                {label: messages[locale].calendar.saturdayShort, id: 6},
                {label: messages[locale].calendar.sundayShort, id: 7},
            ],

            isLaptop: window.matchMedia('(max-width: 1199px)').matches,
            isTablet: window.matchMedia('(max-width: 1100px)').matches,
            isMobile: window.matchMedia('(max-width: 991px)').matches,
            isAdmin: false,
            isRoot: false,
            isRootCompany: false
        };
    },

    methods: {
        /**
         * @param string {string} - Формат день/месяц/год [часы?:мин?:сек?]
         * @return {Date}
         */
        stringToDate(string) {
            const split = string.split(" ");

            const dateInfo = split[0].split("/");
            const date = new Date();
            date.setDate(+dateInfo[0]);
            date.setMonth(+dateInfo[1] - 1);
            date.setFullYear(+dateInfo[2]);
            if (split.length > 1) {
                const timeInfo = split[1].split("-");
                if (timeInfo.length > 0) {
                    date.setHours(+timeInfo[0]);
                }
                if (timeInfo.length > 1) {
                    date.setMinutes(+timeInfo[1]);
                }
                if (timeInfo.length > 2) {
                    date.setSeconds(+timeInfo[2]);
                }
            }

            return date;
        },

        /**
         *
         * @param timestamp {number}
         * @returns {Date}
         */
        timestampToDate(timestamp) {
            const date = new Date();
            date.setTime(timestamp * 1000);

            return date;
        },

        /**
         *
         * @param date {Date}
         * @param time {boolean} - показывать ли время
         * @param days {boolean} - показывать ли дату
         * @return {string} - Формат [год-месяц-день] [часы:минуты]
         */
        dateToString(date, time = false, days = true) {
            const year = date.getFullYear();
            const day = "0" + date.getDate();
            const month = "0" + (date.getMonth() + 1);


            let ret = "";
            if (days) {
                ret += day.substring(day.length - 2) + "/" + month.substring(month.length - 2) + "/" + year;
            }
            if (time) {
                const minutes = "0" + date.getMinutes();
                const hours = "0" + date.getHours();
                ret += ` ${hours.substring(hours.length - 2)}:${minutes.substring(minutes.length - 2)}`;
            }

            return ret.trim();
        },

        getMonthDays(year, month) {
            const date = new Date(year, month + 1, 0);
            return date.getDate();
        },

        getWeeksCount(year, month) {
            const days = this.getMonthDays(year, month);
            return Math.ceil(days / 7);
        },

        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        },

        timeToString(time) {
            return time.map(el => {
                let v = String(el);
                if (v.length === 1) {
                    v = "0" + v;
                }
                return v;
            })
                .join(":");
        },

        setCookie(name, value, options = {}) {
            options = {
                path: '/',
                ...options
            };

            if (options.expires instanceof Date) {
                options.expires = options.expires.toUTCString();
            }

            let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

            for (let optionKey in options) {
                updatedCookie += "; " + optionKey;
                let optionValue = options[optionKey];
                if (optionValue !== true) {
                    updatedCookie += "=" + optionValue;
                }
            }

            document.cookie = updatedCookie;
        },

        deleteCookie(name) {
            this.setCookie(name, "", {
                'max-age': -1
            })
        },

        /**
         *
         * @returns {boolean}
         */
        validateInputs() {
            if (!this.inputsForValidate) {
                return true;
            }


            let ret = true;
            const starts = this.validatePrefix || "input";

            for (let key in this.inputsForValidate) {
                if (!key.startsWith(starts)) {
                    continue;
                }
                let valid = true;

                /**
                 * @type {HTMLInputElement|Vue}
                 */
                let input = this.inputsForValidate[key];
                if (!input) continue;

                if (input.$refs && input.$refs.select) {
                    let value = input.val;
                    if (Array.isArray(value)) {
                        valid = value.length > 0;
                    } else if (value instanceof Object) {
                        valid = Object.keys(value).length > 0;
                    } else {
                        valid = Boolean(input.value);
                    }
                    input.$refs.select.$el.classList.toggle("invalid", !valid);
                } else if (input.$refs && input.$refs.selectRadio) {
                    valid = input.radioOptions.filter(el => el.checked).length > 0;
                    input.$refs.selectRadio.$el.classList.toggle("invalid", !valid);
                } else if (input.$refs && input.$refs.checkbox) {
                    valid = input.$refs.checkbox.checked;
                    input.$refs.checkbox.classList.toggle("invalid", !valid);
                } else {

                    let origin = null;
                    if (input.$refs && input.$refs.input) {
                        origin = input;
                        input = input.$refs.input;
                    }

                    if (origin && origin.type === "worktime") {
                        if (!input.value) {
                            valid = false;
                        } else {
                            const split = input.value.split("-");
                            const times1 = split[0].split(":");
                            const times2 = split[1].split(":");

                            valid = times1.length === 2 && times2.length === 2;
                            if (valid) {
                                valid = parseInt(times1[0]) <= 23 && parseInt(times1[1]) <= 59
                                    && parseInt(times1[0]) >= 0 && parseInt(times1[1]) >= 0
                                    && parseInt(times2[0]) <= 23 && parseInt(times2[1]) <= 59
                                    && parseInt(times2[0]) >= 0 && parseInt(times2[1]) >= 0
                            }
                            if (valid) {
                                valid = (parseInt(times1[0]) * 60 + parseInt(times1[1]))
                                    < (parseInt(times2[0]) * 60 + parseInt(times2[1]));
                            }
                        }
                    } else if (!input.checkValidity()) {
                        if (input.type === "email") {
                            input.reportValidity();
                        }
                        valid = false;
                    }

                    if (!valid) {
                        input.classList.add("invalid");
                    } else {
                        input.classList.remove("invalid");
                    }
                }

                if (ret) {
                    ret = valid;
                }
            }

            return ret;
        },

        getUserFullName(userInfo) {
            if (!userInfo) {
                return "";
            }

            return `${userInfo.lastname} ${userInfo.name} ${userInfo.surname}`;
        },

        getCheckedRows() {
            if (!this.$refs.setting && !this.$refs.settingRef) {
                return [];
            }

            return [...(this.$refs.setting || []), ...(this.$refs.settingRef || [])]
                .filter(el => el.checked);
        },

        confirm() {
            const promise = new ConfirmPromise();

            this.$store.commit("setConfirm", promise);

            return new Promise((resolve, reject) => {
                promise
                    .yes(() => resolve())
                    .no(() => reject());
            });
        },

        exportSetting(type, url) {
            const settings = this.getCheckedRows();
            if (settings.length === 0) return;

            this.$store.commit("setPreloader", true);

            const formData = new FormData();
            settings.forEach(setting => {
                const id = setting.setting.id;

                formData.append("id[]", id);
            });

            fetch(`${this.location}/${url}/${type}`, {
                method: "POST",
                body: formData
            })
                .then(response => {

                    response.blob()
                        .then(blob => {
                            this.$store.commit("setPreloader", false);

                            let filename = response.headers.get('Content-Disposition');
                            if (!filename) {
                                filename = window.Date.now() + "." + this.extensions[type];

                            } else {
                                filename = filename.split('filename=')[1];
                            }

                            const url = URL.createObjectURL(blob);
                            const a = document.createElement("a");
                            a.download = filename;
                            a.href = url;
                            a.click();
                            a.remove();
                            URL.revokeObjectURL(url);

                        });

                });
        },

        printSettings(url) {
            const settings = this.getCheckedRows();
            if (settings.length === 0) {
                return;
            }

            const form = document.createElement("form");
            form.action = `${this.location}/${url}/print`;
            form.method = "post";
            form.target = "_blank";

            let html = "";
            settings.forEach(el => {
                html += `<input type="hidden" name="id[]" value="${el.setting.id}">`;
            });
            form.insertAdjacentHTML("beforeend", html);
            document.body.append(form);
            form.submit();
            form.remove();

        },


    },

    mounted() {
        window.addEventListener('resize', () => {
            this.isMobile = window.matchMedia('(max-width: 991px)').matches;
            this.isLaptop = window.matchMedia('(max-width: 1199px)').matches;
            this.isTablet = window.matchMedia('(max-width: 1100px)').matches;

        });

        if (!window.USER_INFO) {
            window.USER_INFO = fetch(this.location + "/profile/info")
                .then(data => data.json())
                .then(data => {
                    if (data.status === "error") {
                        if (location.pathname === "/auth") {
                            return;
                        }
                        Cookie.deleteCookie("id");
                        Cookie.deleteCookie("hash");
                        location.href = "/auth";
                        return;
                    }

                    return data;
                });
        }

        window.USER_INFO.then(data => {
            this.isAdmin = data.group_id === 2;
            this.isRoot = data.group_id === 2 && data.company_id === 1;
            this.isRootCompany = data.company_id === 1;
        });

    }
});


const i18n = new VueI18n({
    locale,
    messages
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')