import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showSidebar: window.matchMedia('(max-width: 1100px)').matches ? false : true,
        showMailMenu: window.matchMedia('(max-width: 1100px)').matches ? false : true,
        breadcrumbs: null,
        preloader: false,
        isPercentage: false,
        preloaderPercent: 0,
        confirm: false,
        /**
         * @type {ConfirmPromise}
         */
        confirmPromise: null,
        menus: [],
        permissions: [],
        roles: [],
        accountingNotify: 0
    },
    mutations: {
        toggleSidebar(state, status) {
            state.showSidebar = status
        },
        toggleMailMenu(state, status) {
            state.showMailMenu = status;

            if (status) {
                document.documentElement.classList.add('locked');
            } else {
                document.documentElement.classList.remove('locked');
            }
        },

        setBreadCrumbs(state, arr) {
            state.breadcrumbs = arr;
        },

        setPreloader(state, opened) {
            state.preloader = opened;
        },

        setIsPercentagePreloader(state, opened) {
            state.isPercentage = opened;
        },

        setPreloaderPercent(state, percent) {
            state.preloaderPercent = percent;
        },

        /**
         *
         * @param state
         * @param promise {ConfirmPromise|null}
         */
        setConfirm(state, promise) {
            if (promise) {
                state.confirm = true;
                state.confirmPromise = promise;
            } else {
                state.confirm = false;
            }
        },

        setMenu(state, menus) {
            state.menus = menus;
        },

        setPermissions(state, permissions) {
            state.permissions = permissions;
        },

        setRoles(state, roles) {
            state.roles = roles;
        },

        setAccountingNotify(state, accountingNotify) {
            state.accountingNotify = accountingNotify;
        }
    },
    actions: {},
    modules: {}
})
