<template>
    <div class="langs-dropdown" v-click-outside="hidePopup">
        <div class="langs-dropdown__icon"
             @click="showPopup"
             ref="btnLang">
            <img :src="currentImg" alt="">
        </div>
        <ul class="langs-dropdown__list" v-show="isShowPopup">
            <li class="langs-dropdown__item"
                v-for="lang in languages" :key="lang.id"
            >
                <div class="form__group--radio">
                    <input
                            type="radio"
                            class="form__control"
                            :id="lang.id"
                            :value="lang.name"
                            v-model="currentChoice"
                            @change="onChangeLang"
                            @click="hidePopup"
                    />
                    <label class="form__label form__label--visible" :for="lang.id">
                        <div class="langs-dropdown__icon langs-dropdown__icon--inlist">
                            <img :src="lang.src" alt="">
                        </div>
                        {{ lang.name }}
                    </label>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import {LanguageHelper} from "@/helpers/LanguageHelper";

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data() {
        return {
            currentChoice: "ru",
            popup: null,
            isShowPopup: false,
            languages: [
                {
                    id: 1,
                    name: "ru",
                    src: require("@/assets/img/languages/ru.svg")
                },
                {
                    id: 2,
                    name: "en",
                    src: require("@/assets/img/languages/eng.svg")
                },
            ]
        };
    },
    methods: {
        showPopup() {
            this.isShowPopup = true;
        },
        hidePopup() {
            this.isShowPopup = false;
        },

        onChangeLang() {
            this.$store.commit("setPreloader", true);

            LanguageHelper.setLang(this.currentChoice)
                .then(() => location.reload());
        }
    },
    computed: {
        currentImg() {
            return this.languages.find(lang => lang.name == this.currentChoice).src;
        }
    },

    watch: {
        currentChoice(value) {
            this.$i18n.locale = value;
        }
    },

    mounted() {
        let locale = localStorage.getItem("lang") || "ru";
        if (!["ru", "en"].includes(locale)) {
            locale = "ru";
        }
        this.currentChoice = locale;
        window.USER_INFO
            .then(data => {
                if (data && Object.hasOwn(data, "lang") && data.lang && data.lang !== this.currentChoice) {
                    localStorage.setItem("lang", data.lang);
                    location.reload();
                }
            });
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base";

.langs-dropdown {
  position: relative;
  margin-left: rem(16px);

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: rem(40px);
    height: rem(40px);
    border-radius: 50%;
    overflow: hidden;

    &--inlist {
      margin-right: rem(10px);
    }
  }

  &__list {
    position: absolute;
    right: 0;
    background-color: #fff;
    list-style: none;
    padding: rem(30px) rem(25px);
    z-index: 1000;
    border-radius: 4px;
    box-shadow: 0 3px 9px 0 rgba(138, 138, 138, 0.12);
    border: solid 1px #d3dde6;
  }

  &__item {
    margin-bottom: rem(24px);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>