import {User} from "@/api/users/User";

export class LanguageHelper {
    static getCurrent(def = "ru") {
        if (!def) {
            def = "ru";
        }
        return localStorage.getItem("lang") || def;
    }

    static setLang(lang = "ru") {
        if (!lang) {
            lang = "ru";
        }
        localStorage.setItem("lang", lang);
        return User.setLang(lang);
    }

}