<template>
    <div class="header">
        <div class="header__wrap">
            <router-link to="/" class="header__logo">
                <img class="company--avatar" v-if="avatar" :src="avatar">
                <svg v-else viewBox="0 0 175 49.845" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(-7.674 -88.496)">
                        <path
                                fill="#fff"
                                transform="translate(-35.846 1.753)"
                                d="M129.138 108.89a26.274 26.274 0 0 1-2.7-1.335 2.8 2.8 0 0 1-.831-.769 1.657 1.657 0 0 1-.261-.932 1.722 1.722 0 0 1 .646-1.394 2.841 2.841 0 0 1 1.855-.534 9.024 9.024 0 0 1 2.068.248 20.838 20.838 0 0 1 2.659.873l1.374-3.14a21.3 21.3 0 0 0-2.975-.99 12.4 12.4 0 0 0-2.988-.352 7.792 7.792 0 0 0-5 1.446 5.276 5.276 0 0 0-1.257 6.319 5.977 5.977 0 0 0 1.47 1.759 12.462 12.462 0 0 0 2.762 1.576 25.545 25.545 0 0 1 2.6 1.29 3.624 3.624 0 0 1 .969.841 1.609 1.609 0 0 1 .33 1 1.708 1.708 0 0 1-.749 1.511 3.822 3.822 0 0 1-2.15.508 10.7 10.7 0 0 1-2.576-.352 24.234 24.234 0 0 1-3.442-1.212V119a13.389 13.389 0 0 0 5.688 1.147 8.838 8.838 0 0 0 5.449-1.486 5.224 5.224 0 0 0 .921-7.211 10.769 10.769 0 0 0-3.862-2.56z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-42.945 1.605)"
                                d="m152.16 108.83-4.161-7.844h-4.617l6.664 11.765v7.283h4.232v-7.414l6.664-11.635h-4.59z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-52.766 1.605)"
                                d="m188.47 111.57a4.475 4.475 0 0 0-2.755-1.511v-0.13a3.405 3.405 0 0 0 2.247-1.316 4.452 4.452 0 0 0 0.846-2.8 3.955 3.955 0 0 0-1.931-3.668q-1.929-1.153-6.2-1.153h-6.252v19.049h7.516a8.925 8.925 0 0 0 5.394-1.46 5.3 5.3 0 0 0 1.14-7.01zm-9.7 5.824v-6.319l6.941 3.479z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(141.68 102.59)"
                                d="M0 0H4.26V19.049H0z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-65.704 1.605)"
                                d="m224.08 108.83-4.164-7.844h-4.619l6.664 11.765v7.283h4.236v-7.414l6.664-11.635h-4.589z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-74.954 1.753)"
                                d="M256.582 111.45a10.763 10.763 0 0 0-3.861-2.56 26.2 26.2 0 0 1-2.7-1.335 2.8 2.8 0 0 1-.831-.769 1.657 1.657 0 0 1-.261-.932 1.723 1.723 0 0 1 .645-1.394 2.845 2.845 0 0 1 1.855-.534 9.029 9.029 0 0 1 2.068.248 20.832 20.832 0 0 1 2.658.873l1.375-3.14a21.3 21.3 0 0 0-2.975-.99 12.407 12.407 0 0 0-2.989-.352 7.791 7.791 0 0 0-4.995 1.446 5.276 5.276 0 0 0-1.257 6.319 5.966 5.966 0 0 0 1.47 1.759 12.462 12.462 0 0 0 2.762 1.576 25.616 25.616 0 0 1 2.6 1.29 3.623 3.623 0 0 1 .969.841 1.609 1.609 0 0 1 .329 1 1.707 1.707 0 0 1-.748 1.511 3.822 3.822 0 0 1-2.15.508 10.708 10.708 0 0 1-2.577-.352 24.229 24.229 0 0 1-3.442-1.212V119a13.389 13.389 0 0 0 5.688 1.147 8.837 8.837 0 0 0 5.449-1.486 5.226 5.226 0 0 0 .914-7.212z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-12.645)"
                                d="M47.632 98.529l.08.027L58.585 88.5z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-12.682)"
                                d="M67.267 98.529L58.621 88.5 47.749 98.556l12.511 4.21z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-11.247 -6.011)"
                                d="m68.77 107.8-1.627-1.867-7.729 4.667-14.195-5.02-2.005 1.855 16.5 5.84z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-9.64 -7.754)"
                                d="m69.7 112.45-1.627-1.867-10.263 6.206-17.665-6.253-2.005 1.855 19.968 7.068z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-8.033 -9.358)"
                                d="m70.624 116.96-1.624-1.867-12.8 7.739-21.136-7.482-2.004 1.851 23.44 8.3z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-6.427 -10.936)"
                                d="m71.551 121.44-1.627-1.867-15.33 9.271-24.606-8.71-2.005 1.855 26.909 9.525z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-4.82 -12.515)"
                                d="m72.479 125.93-1.627-1.867-17.864 10.8-28.077-9.938-2.005 1.855 30.379 10.754z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-3.213 -14.093)"
                                d="m73.406 130.42-1.627-1.867-20.4 12.335-31.545-11.166-2.005 1.855 33.85 11.982z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(-1.607 -15.671)"
                                d="m74.333 134.9-1.627-1.867-22.932 13.867-35.018-12.4-2.005 1.855 37.321 13.21z"
                        />
                        <path
                                fill="#fff"
                                transform="translate(0 -17.25)"
                                d="M48.168 152.92L9.68 139.3l-2.005 1.855 40.79 14.435 26.795-16.2-1.627-1.867z"
                        />
                    </g>
                </svg>
            </router-link>

            <nav class="header__header-menu">
                <button
                        class="header-menu__btn header-menu__btn--prev"
                        v-touch:longtap="(event) => scrollRight(event)"
                        v-touch="(event) => scrollRight(event)"
                        @click="scrollRight(event)"
                        v-if="isMobile"
                >
                    <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.41"
                            height="12"
                            viewBox="0 0 7.41 12"
                    >
                        <path
                                fill="#d3dde6"
                                d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"
                                transform="rotate(180 8 8.875)"
                        />
                    </svg>
                </button>
                <ul class="header-menu__list" ref="menu">
                    <HeaderMenuItem v-for="item in arr_menu_items" :key="item.id"
                                    :label="item.label"
                                    :route="item.link"
                                    :notice_count="item.notice_count"
                    />
                </ul>
                <button
                        class="header-menu__btn header-menu__btn--next"
                        v-touch:longtap="(event) => scrollLeft(event)"
                        v-touch="(event) => scrollLeft(event)"
                        @click="scrollLeft(event)"
                        v-if="isMobile"
                >
                    <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.41"
                            height="12"
                            viewBox="0 0 7.41 12"
                    >
                        <path
                                fill="#d3dde6"
                                d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"
                                transform="rotate(180 8 8.875)"
                        />
                    </svg>
                </button>
            </nav>

            <div class="header__header-profile header-profile" v-if="!isMobile" v-click-outside="hideDropdown">
                <div
                        class="header-profile__profile-info"
                        @click="showDropdown = !showDropdown"
                        ref="dropdownParent"
                        :class="showDropdown ? 'header-profile__profile-info--expanded' : null"
                >
                    {{ name }}
                    <div class="header-profile__icon">
                        <img class="header-profile__avatar" v-if="profileAvatar" :src="profileAvatar">
                        <svg v-else viewBox="0 0 43 43" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none">
                                <path
                                        d="M21.5 0A21.5 21.5 0 1 1 0 21.5 21.5 21.5 0 0 1 21.5 0z"
                                />
                                <path
                                        d="M21.5 2c-2.633 0-5.187.515-7.59 1.532-2.321.982-4.407 2.388-6.199 4.18-1.791 1.79-3.197 3.877-4.18 6.198C2.516 16.313 2 18.867 2 21.5s.515 5.187 1.532 7.59c.982 2.321 2.388 4.407 4.18 6.199 1.79 1.791 3.877 3.197 6.198 4.18C16.313 40.484 18.867 41 21.5 41s5.187-.515 7.59-1.532c2.321-.982 4.407-2.388 6.199-4.18 1.791-1.79 3.197-3.877 4.18-6.198C40.484 26.687 41 24.133 41 21.5s-.515-5.187-1.532-7.59c-.982-2.321-2.388-4.407-4.18-6.199-1.79-1.791-3.877-3.197-6.198-4.18C26.687 2.516 24.133 2 21.5 2m0-2C33.374 0 43 9.626 43 21.5S33.374 43 21.5 43 0 33.374 0 21.5 9.626 0 21.5 0z"
                                        fill="#f7f7f7"
                                />
                            </g>
                            <path
                                    transform="translate(-1201 -581.88)"
                                    d="M1222.259 612.555c-2.229 0-4.149-1.208-4.557-2.748h.957a.6.6 0 0 0 .6-.605v-2.878a6.667 6.667 0 0 0 6.05 0v2.876a.605.605 0 0 0 .605.605h.9c-.408 1.54-2.327 2.748-4.558 2.748m-5.209-11.975l-.406-4.151v-.052a6.554 6.554 0 0 1-.042-.712 5.933 5.933 0 1 1 11.854 0 7.363 7.363 0 0 1-.044.755l-.409 4.165a5.481 5.481 0 0 1-10.949 0m11.794 8.219h-2.017v-3.336a7.3 7.3 0 0 0 2.377-4.765l.408-4.19a7.564 7.564 0 0 0 .05-.844 7.142 7.142 0 1 0-14.275 0 7.412 7.412 0 0 0 .047.813v.06l.407 4.157a7.189 7.189 0 0 0 2.41 4.769v3.334h-2.194a8.54 8.54 0 0 0-8.732 8.814.6.6 0 1 0 1.21 0 7.625 7.625 0 0 1 7.522-7.806h.679c.379 2.177 2.811 3.905 5.754 3.905s5.375-1.728 5.754-3.905h.738a7.624 7.624 0 0 1 7.522 7.806.605.605 0 0 0 1.21 0 8.916 8.916 0 0 0-8.733-9.069"
                                    fill="#f7f7f7"
                            />
                        </svg>
                    </div>
                </div>

                <div
                        class="header-profile__dropdown"
                        v-show="showDropdown"
                        ref="dropdown"
                        tabindex="0"
                >
                    <div class="header-profile__username">{{ lastname }} {{ name }} {{ surname }}</div>

                    <ul class="header-profile__list">
                        <li class="header-profile__item" v-for="sibling in siblings" :key="sibling.id">
                            <a class="header-profile__link" href="#" @click.prevent="authSibling">{{
                                    sibling.fullName
                                }}</a>
                        </li>
                        <li class="header-profile__item">
                            <router-link to="/account" class="header-profile__link">
                                <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                >
                                    <path
                                            fill="none"
                                            stroke="#d3dde6"
                                            d="M9.5 0A9.5 9.5 0 1 0 19 9.5 9.5 9.5 0 0 0 9.5 0m2.8 6.875a2.8 2.8 0 1 1-2.8-2.8 2.8 2.8 0 0 1 2.8 2.8M6.158 13.77a7.284 7.284 0 0 1-.563-.49 4.645 4.645 0 0 1-.563-.637 4.865 4.865 0 0 1-.331-.49.26.26 0 0 1-.012-.233 3 3 0 0 1 .282-.514 3.46 3.46 0 0 1 2.412-1.519.46.46 0 0 1 .355.086 2.989 2.989 0 0 0 3.526 0 .516.516 0 0 1 .355-.086 3.485 3.485 0 0 1 2.412 1.518 2.619 2.619 0 0 1 .282.514.209.209 0 0 1-.037.233 4.293 4.293 0 0 1-.331.49 5.659 5.659 0 0 1-.563.637 5.39 5.39 0 0 1-.563.49 5.574 5.574 0 0 1-3.291 1.1h-.079a5.574 5.574 0 0 1-3.291-1.1"
                                            transform="translate(.5 .5)"
                                    />
                                </svg>

                                {{ $t("profile.account") }}
                            </router-link>
                        </li>
                        <li class="header-profile__item">
                            <router-link to="/mail" class="header-profile__link">
                                <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.409"
                                        height="20"
                                        viewBox="0 0 17.409 20"
                                >
                                    <path
                                            fill="none"
                                            stroke="#d3dde6"
                                            d="M12.2-8.687a2.008 2.008 0 0 0 2.051-1.949h-4.1A2 2 0 0 0 12.2-8.687zm6.153-5.846V-19.4a5.974 5.974 0 0 0-4.615-6.158v-.663a1.5 1.5 0 0 0-1.538-1.466 1.5 1.5 0 0 0-1.538 1.462v.663A5.957 5.957 0 0 0 6.051-19.4v4.872L4-12.584v.974h16.409v-.974z"
                                            transform="translate(-3.5 28.187)"
                                    />
                                </svg>

                                {{ $t("links.notifications") }}
                            </router-link>
                        </li>
                    </ul>

                    <ul class="header-profile__list">
                        <li class="header-profile__item">
                            <div @click.prevent="signOut" class="header-profile__link">
                                <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 18 18"
                                >
                                    <path
                                            d="M269.737 424.439l.161.161a.884.884 0 0 0 1.25 0l3.475-3.475a1.273 1.273 0 0 0 0-1.8l-3.475-3.475a.884.884 0 0 0-1.25 0l-.161.161a.884.884 0 0 0 0 1.249l1.957 1.965h-6.518a.884.884 0 0 0-.884.884v.232a.884.884 0 0 0 .884.884h6.518l-1.957 1.965a.884.884 0 0 0 0 1.249z"
                                            fill="#d3dde6"
                                            transform="translate(-258.975 -411.225)"
                                    />
                                    <path
                                            d="M276.975 413.225a2 2 0 0 0-2-2h-14a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2 2 0 0 0 2-2v-4h-2v4h-14v-14h14v4h2z"
                                            fill="#d3dde6"
                                            transform="translate(-258.975 -411.225)"
                                    />
                                </svg>

                                {{ $t("profile.logOut") }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!--            <LangsDropdown/>-->
            <div class="header-profile-user-count">
                <div class="header-profile-user-count__item">{{ $t("profile.balance") }} {{ balance }}
                    {{ $t("common.rub") }}
                </div>
            </div>
        </div>


        <div class="table__modal modal" v-if="debtsCount > 0">
            <div class="modal__wrap modal__wrap--heading-center">
                <div class="modal__head">
                    <div class="modal__title">{{ $t("modal.debts") }}{{ debtsCount }}</div>
                </div>
                <div class="modal__foot">
                    <button
                            class="modal__btn modal__btn--green"
                            @click.prevent="$router.push('/reports/stats'); debtsCount = 0"
                            style="width: auto; max-width: 100%;"
                    >
                        {{ $t("modal.show") }}
                    </button>
                    <button class="modal__btn modal__btn--red" @click.prevent="debtsCount = 0">
                        {{ $t("modal.no") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import {createPopper} from "@popperjs/core";
import HeaderMenuItem from "@/components/layout/header/HeaderMenuItem.vue";
// import LangsDropdown from "@/components/elements/LangsDropdown.vue";
import {UserHelper} from "@/helpers/UserHelper";
import {Setting} from "@/api/settings/Setting";
import {Menu} from "@/api/settings/Menu";
import {Role} from "@/api/users/Role";

export default {
    directives: {
        clickOutside: vClickOutside.directive,
    },
    components: {
        HeaderMenuItem,
        // TODO fix style for langs and payments
        // LangsDropdown
    },

    props: {
        notifications: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            showDropdown: false,
            popup: null,
            name: "",
            lastname: "",
            surname: "",

            debtsCount: 0,

            logOutTimeOut: null,
            logOutTime: 0,
            avatar: "",
            profileAvatar: "",
            siblings: [],
            balance: 0
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.popup = createPopper(
                this.$refs.dropdownParent,
                this.$refs.dropdown,
                {
                    placement: "bottom",
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 10],
                            },
                        },
                    ],
                }
            );
        });

        this.loadSettings();

        window.USER_INFO
            .then(data => {
                this.lastname = data.lastname;
                this.name = data.name;
                this.surname = data.surname;

                let avatar = data.company.avatar;
                if (avatar) {
                    this.avatar = this.location + avatar;
                }
                const profileAvatar = data.avatar;
                if (profileAvatar) {
                    this.profileAvatar = this.location + profileAvatar;
                }
                this.balance = data.balance;

                this.siblings = data.siblings;

                const lastUpdate = Number(localStorage.getItem("lastUpdate")) || 0;
                if (
                    lastUpdate < data.company.last_settings_update
                    || !localStorage.getItem("permissions")
                    || !localStorage.getItem("menu")
                    || !localStorage.getItem("auth")
                    || !localStorage.getItem("roles")
                ) {
                    this.loadSettings(true);
                }

                if (data.group_id === 11) {
                    return fetch(this.location + "/statistic/debt/count");
                }

                return null;
            })
            .then(data => data?.json())
            .then(data => {
                if (!data) {
                    return;
                }

                this.debtsCount = parseInt(data.data, 10);
            });
    },
    watch: {
        showDropdown() {
            this.popup.update()
        }
    },
    methods: {
        hideDropdown() {
            this.showDropdown = false;
        },
        scrollRight() {
            var scroll = this.$refs.menu.scrollLeft;
            this.$refs.menu.scrollLeft = scroll - 10;
        },
        scrollLeft() {
            var scroll = this.$refs.menu.scrollLeft;
            this.$refs.menu.scrollLeft = scroll + 10;
        },

        signOut() {
            UserHelper.signOut();
        },

        setLogOut() {
            clearTimeout(this.logOutTimeOut);

            if (!this.logOutTime) {
                return;
            }

            this.logOutTimeOut = setTimeout(() => {
                UserHelper.signOut();
            }, this.logOutTime * 1000);
        },

        loadSettings(isNeedUpdate = false) {
            const promises = [];
            if (isNeedUpdate) {
                promises.push(
                    fetch(this.location + "/permissions/current")
                        .then(data => data.json())
                        .then(data => {
                            localStorage.setItem("permissions", JSON.stringify(data.data));
                            this.$store.commit("setPermissions", data.data);
                        }),
                    Menu.get()
                        .then(data => {
                            localStorage.setItem("menu", JSON.stringify(data));
                            this.$store.commit("setMenu", data)
                        }),
                    Setting
                        .getAuthSettings()
                        .then(auth => {
                            localStorage.setItem("auth", JSON.stringify(auth));
                            if (!auth.min) {
                                return;
                            }

                            this.logOutTime = auth.min * 60;
                            this.setLogOut();
                            document.addEventListener("mousemove", () => this.setLogOut());
                        }),
                    Role.get()
                        .then(data => {
                            const roles = data.toArray();
                            localStorage.setItem("roles", JSON.stringify(roles));
                            this.$store.commit("setRoles", roles);
                        })
                );
            } else {
                promises.push(
                    new Promise(resolve => {
                        const str = localStorage.getItem("permissions");
                        const permissions = str ? JSON.parse(str) : {};
                        this.$store.commit("setPermissions", permissions);

                        resolve();
                    }),
                    new Promise(resolve => {
                        const str = localStorage.getItem("menu");
                        const menu = str ? JSON.parse(str) : [];
                        this.$store.commit("setMenu", menu);

                        resolve();
                    }),
                    new Promise(resolve => {
                        const str = localStorage.getItem("auth");
                        const auth = str ? JSON.parse(str) : {};

                        if (!auth.min) {
                            return;
                        }

                        this.logOutTime = auth.min * 60;
                        this.setLogOut();
                        document.addEventListener("mousemove", () => this.setLogOut());

                        resolve();
                    }),
                    new Promise(resolve => {
                        const str = localStorage.getItem("roles");
                        const roles = str ? JSON.parse(str) : [];
                        this.$store.commit("setRoles", roles);

                        resolve();
                    })
                );
            }

            if (isNeedUpdate) {
                Promise
                    .all(promises)
                    .then(() => window.USER_INFO)
                    .then(data => {
                        localStorage.setItem("lastUpdate", data.company.last_settings_update);
                    });
            }

        },

        authSibling(id) {
            UserHelper.switchUser(id);
        }
    },

    computed: {

        arr_menu_items() {
            const arr = [];

            if (this.permissions['documents.access']) {
                arr.push({
                    id: 70,
                    link: '/documents',
                    label: this.$t("links.documents"),
                    notice_count: 0,
                    name: "documents",
                    order: 0
                });
            }
            if (this.permissions['affairs.access']) {
                arr.push({
                    id: 71,
                    link: '/affairs',
                    label: this.$t("links.affairs"),
                    notice_count: 0,
                    name: "affairs",
                    order: 1
                });
            }
            if (this.permissions['tasks.access']) {
                arr.push({
                    id: 72,
                    link: '/tasks',
                    label: this.$t("links.tasks"),
                    notice_count: 0,
                    name: "tasks",
                    order: 2
                });
            }
            if (this.permissions['dealings.access']) {
                arr.push({
                    id: 73,
                    link: '/deals',
                    label: this.$t("links.deals"),
                    notice_count: 0,
                    name: "deals",
                    order: 3
                });
            }
            if (this.permissions['analytics.access']) {
                arr.push({
                    id: 74,
                    link: '/analytics',
                    label: this.$t("links.analytics"),
                    notice_count: 0,
                    order: 4
                });
            }
            if (this.permissions['orders.access']) {
                arr.push({
                    id: 75,
                    link: '/orders',
                    label: this.$t("links.orders"),
                    notice_count: 0,
                    name: "orders",
                    order: 5
                });
            }

            for (let name in this.notifications) {
                const find = arr.find(el => el.name === name);
                if (!find) {
                    continue;
                }

                find.notice_count = this.notifications[name];
            }

            for (let i = 0; i < arr.length; i++) {
                const menu = arr[i];
                const find = this.$store.state.menus.find(el => el.id === menu.id);
                if (!find) {
                    continue;
                }

                if (Object.hasOwn(find, "order")) {
                    menu.order = find.order;
                }
                if (!find.enabled) {
                    arr.splice(i, 1);
                    i--;
                }
            }
            arr.sort((a, b) => a.order - b.order);

            return arr;
        }
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/base";

.company--avatar {
  max-width: 100%;
  max-height: 50px;
}

.header-profile__avatar {
  max-width: 100%;
  max-height: 100%;
}

.header {
  background-color: $dark;

  &__wrap {
    width: 100%;
    //max-width: 1350px;
    padding: rem(14px 0);
    @include contentPaddings;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    width: 100%;
    max-width: 175px;
    display: block;

    @include tablet() {
      max-width: 135px;
    }

    @include mobile {
      max-width: 80px;
      height: 23px;
    }
  }

  &__header-menu {
    margin-left: rem(62px);

    @include lg {
      margin-left: rem(30px);
    }

    @include mobile {
      margin-left: rem(14px);
      position: relative;
      overflow: hidden;
      height: 10px;
      padding: rem(0 18px);
    }
  }

  &__header-profile {
    margin-left: auto;
    position: relative;
  }
}

.header-menu {
  &__list {
    @include unlist;
    display: flex;
    align-items: center;

    @include mobile {
      overflow: scroll;
      padding-bottom: 50px;
    }
  }

  &__btn {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
      transform: rotate(180deg);
      transform-origin: center;
    }
  }

  &__item {
    margin: rem(0 17.5px);
    display: flex;

    @include lg {
      margin: rem(0 8px);
    }

    @include tablet {
      margin: rem(0 7px);
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__link {
    color: #d3dde6;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;

    &--active {
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -32px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $accent;
      }
    }

    @include mobile {
      font-size: rem(8px);
    }
  }
}

.header-profile {
  &__profile-info {
    font-size: rem(18px);
    color: #f7f7f7;
    letter-spacing: 0.36px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &::after {
      content: "";
      background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7.41' viewBox='0 0 12 7.41'%3E%3Cg%3E%3Cpath fill='%23f7f7f7' d='M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z' transform='rotate(90 13.17 4.58)'/%3E%3C/g%3E%3C/svg%3E%0A");
      display: block;
      width: 12px;
      height: 8px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 8px;
    }

    &--expanded {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__icon {
    width: 43px;
    height: 43px;
    margin-left: rem(12px);
  }

  &__dropdown {
    position: absolute;
    background: #fff;
    width: 240px;
    z-index: 100;
    border-radius: 7px;
    box-shadow: 0 3px 6px 0 rgba(82, 87, 93, 0.1);
    border: solid 1px #d3dde6;
  }

  &__username {
    padding: rem(8px 26px);
    text-transform: uppercase;
    font-size: rem(18px);
    color: $dark;
  }

  &__list {
    @include unlist;
    padding: rem(12px 26px);
    border-top: solid 1px #d3dde6;
  }

  &__item {
    margin-bottom: rem(8px);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $dark;
    font-size: rem(14px);

    svg {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }
  }
}

.header-profile-user-count {
  margin-left: 25px;

  &__item {
    color: #5FCF65;
    font-size: 12px;
    line-height: 120%;
    font-weight: 400;
    text-decoration: underline;
    margin-bottom: 5px;
  }
}
</style>