<template>
    <div class="mini-account">
        <div class="mini-account__wrap">
            <div class="mini-account__icon">
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                >
                    <g stroke="#017aff">
                        <g
                                fill="none"
                                stroke-width="2px"
                                transform="translate(-76 -40) translate(76 40)"
                        >
                            <circle cx="18" cy="18" r="18" stroke="none"/>
                            <circle cx="18" cy="18" r="17"/>
                        </g>
                        <path
                                fill="#017aff"
                                d="M1219.828 608.6c-1.866 0-3.474-1.011-3.815-2.3h.8a.506.506 0 0 0 .506-.507v-2.409a5.582 5.582 0 0 0 5.065 0v2.409a.506.506 0 0 0 .506.507h.751c-.341 1.289-1.948 2.3-3.816 2.3m-4.361-10.026l-.34-3.475v-.044a5.469 5.469 0 0 1-.035-.6 4.967 4.967 0 1 1 9.924 0 6.144 6.144 0 0 1-.037.632l-.342 3.487a4.589 4.589 0 0 1-9.166 0m9.874 6.881h-1.688v-2.793a6.109 6.109 0 0 0 1.99-3.989l.342-3.508a6.33 6.33 0 0 0 .042-.707 5.98 5.98 0 1 0-11.951 0 6.226 6.226 0 0 0 .039.68v.05l.341 3.48a6.02 6.02 0 0 0 2.018 3.993v2.793h-1.836a7.15 7.15 0 0 0-7.311 7.379.507.507 0 1 0 1.013 0 6.383 6.383 0 0 1 6.3-6.535h.568c.317 1.823 2.354 3.27 4.817 3.27s4.5-1.447 4.818-3.27h.618a6.383 6.383 0 0 1 6.3 6.535.507.507 0 1 0 1.013 0 7.464 7.464 0 0 0-7.311-7.593"
                                transform="translate(-76 -40) translate(-1126.043 -542.923)"
                        />
                    </g>
                </svg>
            </div>
            <div class="mini-account__username">{{ fullname }}</div>
            <a href="" class="mini-account__logout" @click.prevent="showLogoutModal()">
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                >
                    <path
                            d="M269.737 424.439l.161.161a.884.884 0 0 0 1.25 0l3.475-3.475a1.273 1.273 0 0 0 0-1.8l-3.475-3.475a.884.884 0 0 0-1.25 0l-.161.161a.884.884 0 0 0 0 1.249l1.957 1.965h-6.518a.884.884 0 0 0-.884.884v.232a.884.884 0 0 0 .884.884h6.518l-1.957 1.965a.884.884 0 0 0 0 1.249z"
                            fill="#d3dde6"
                            transform="translate(-258.975 -411.225)"
                    />
                    <path
                            d="M276.975 413.225a2 2 0 0 0-2-2h-14a2.006 2.006 0 0 0-2 2v14a2.006 2.006 0 0 0 2 2h14a2 2 0 0 0 2-2v-4h-2v4h-14v-14h14v4h2z"
                            fill="#d3dde6"
                            transform="translate(-258.975 -411.225)"
                    />
                </svg>
            </a>
        </div>

        <div class="table__modal modal" v-if="showModal">
            <div class="modal__wrap modal__wrap--heading-center">
                <div class="modal__head">
                    <div class="modal__title">
                        {{ $t("profile.logOutConfirmation") }}
                    </div>
                </div>
                <div class="modal__foot">
                    <button
                            class="modal__btn modal__btn--green"
                            @click.prevent="$router.push('/auth')"
                    >
                        {{ $t("modal.yes") }}
                    </button>
                    <button class="modal__btn modal__btn--red" @click="showModal = false">
                        {{ $t("modal.no") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            fullname: ""
        }
    },
    methods: {
        showLogoutModal() {
            this.showModal = true;
        }
    },

    mounted() {
        window.USER_INFO
            .then(data => {
                this.fullname = `${data.lastname} ${data.name} ${data.surname}`;
            });
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/base";

.mini-account {
  &__wrap {
    padding-top: rem(68px);
    padding-bottom: rem(22px);
    border-bottom: 1px solid #dfdfdf;
    text-align: center;
  }

  &__icon {
    width: 36px;
    height: 36px;
    margin: auto;
    margin-bottom: rem(14px);
  }

  &__username {
    letter-spacing: 0.32px;
    color: #25305d;
    margin-bottom: rem(12px);
    white-space: nowrap;
  }

  &__logout {
    width: 18px;
    height: 18px;
  }
}
</style>