/**
 *
 * @param obj {Object}
 * @return {URLSearchParams}
 */
export function objectToSearchParams(obj) {
    const urlSearchParams = new URLSearchParams();
    for (let [key, value] of Object.entries(obj)) {
        if (value === null) {
            continue;
        }
        if (Array.isArray(value)) {
            value.forEach(el => urlSearchParams.append(`${key}[]`, el));
            continue;
        }

        urlSearchParams.append(key, value);
    }

    return urlSearchParams;
}