/**
 * @typedef {Object} SettingsParams
 * @property {string} type
 */
import {objectToSearchParams} from "@/helpers/Request";
import {ArrayResponse} from "@/api/response/ArrayResponse";

export class User {

    static auth(userId) {
        const formData = new FormData();
        formData.append("user_id", userId);
        return fetch(window.LOCATION_URL + "/auth/switch", {
            method: "POST",
            body: formData
        })
            .then(data => data.json());
    }

    /**
     *
     * @return {Promise<Object>}
     */
    static getInfo() {
        return fetch(window.LOCATION_URL + "/profile/info")
            .then(data => data.json());
    }

    static restoreUser(userId) {
        const formData = new FormData();
        formData.append("id", userId);

        return fetch(window.LOCATION_URL + "/users/restore", {
            method: "POST",
            body: formData
        })
            .then(data => data.json());
    }

    static setLang(lang) {
        const formData = new FormData();
        formData.append("lang", lang);

        return fetch(window.LOCATION_URL + "/profile/lang", {
            method: "POST",
            body: formData
        })
            .then(data => data.json());
    }

    /**
     *
     * @param params {SettingsParams}
     * @param mapper
     * @return {Promise<ArrayResponse>}
     */
    static getSettings(params = {}, mapper = null) {
        const defaultParams = {
            type: null
        };
        params = {...defaultParams, ...params};

        const sp = objectToSearchParams(params);

        return fetch(window.LOCATION_URL + "/user/settings/index?" + sp.toString())
            .then(data => data.json())
            .then(data => new ArrayResponse(data.data, -1).bind(row => {
                try {
                    return mapper.createFromObj(row);
                } catch {
                    return {
                        key: row.key,
                        name: row.name
                    };
                }
            }));
    }

    /**
     *
     * @param settings {import("@/dto/users/SettingAbstract").SettingAbstract[]}
     */
    static saveSettings(settings) {
        const formData = new FormData();
        settings.forEach(s => s.prepareFormData(formData));

        return fetch(window.LOCATION_URL + "/user/settings/save", {
            method: "POST",
            body: formData
        })
            .then(data => data.json());
    }

}