<template>
    <li
            class="news__news-item"
            :class="item.comments.length && 'news-item--has-comments'"
    >
        <div class="news-item__tag" :class="modifierTag">
            {{ item.title }}
        </div>

        <div v-if="item.attachments.length > 0" class="news-item__attachments">
            <div v-for="(attachment, key) in item.attachments" :key="key">
                <img class="news-item__img" :src="location + attachment.path">
                <div><a :href="location + attachment.path" target="_blank">{{ $t("common.openImage") }}</a></div>
            </div>
        </div>

        <div class="news-item__content" v-html="item.content"></div>
        <template v-if="item.isPoll">
            <div class="news-question">{{ item.polls.question }}</div>
            <template v-if="!item.isVote">
                <Radio class="news-answer" v-for="(answer, id) in item.polls.answer" :label="answer.value" :key="id"
                       :name="'answers_' +item.id" @change="vote(id)"/>
            </template>
            <template v-else>
                <div class="news-answer" v-for="(answer, id) in item.polls.answer" :key="id">
                    {{ answer.value }} - {{ answer.votes }}
                    <span v-if="item.polls.totalAnswer > 0">
            ({{ Math.round(answer.votes * 100 / item.polls.totalAnswer) }}%)
          </span>
                </div>
            </template>
        </template>

        <div class="news-item__foot">
            <time class="news-item__date" :datetime="item.dateСode">
                {{ item.date }}
            </time>

            <template v-if="item.comments.length">
                <div class="news-item__meta news-item__meta--comments">
                    <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                    >
                        <path
                                fill="#0045cb"
                                d="M13.125 10.625h-6.25a.625.625 0 0 0 0 1.25h6.25a.625.625 0 0 0 0-1.25m1.25-3.75h-8.75a.625.625 0 1 0 0 1.25h8.75a.625.625 0 1 0 0-1.25M10 0C4.478 0 0 3.917 0 8.75a8.375 8.375 0 0 0 3.75 6.824V20l4.381-2.658A11.468 11.468 0 0 0 10 17.5c5.523 0 10-3.917 10-8.75S15.523 0 10 0m0 16.25a10.114 10.114 0 0 1-2.113-.229l-2.942 1.768.039-2.9A7.247 7.247 0 0 1 1.25 8.75c0-4.142 3.917-7.5 8.75-7.5s8.75 3.358 8.75 7.5-3.917 7.5-8.75 7.5"
                        />
                    </svg>
                    {{ item.comments.length }}
                </div>

                <button
                        class="news-item__meta news-item__meta--likes"
                        :class="liked && 'news-item__meta--is-liked'"
                        @click.prevent="like()"
                        style="display: none;"
                >
                    <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.1"
                            height="17.894"
                            viewBox="0 0 20.1 17.894"
                    >
                        <path
                                fill="#348cff"
                                stroke="#348cff"
                                stroke-width="0.1px"
                                d="M10 17.795a1.173 1.173 0 0 1-.773-.291c-.808-.706-1.587-1.37-2.274-1.956a42.294 42.294 0 0 1-4.966-4.661A7.444 7.444 0 0 1 0 6.012a6.251 6.251 0 0 1 1.587-4.27 5.378 5.378 0 0 1 4-1.742A5.031 5.031 0 0 1 8.73 1.086 6.43 6.43 0 0 1 10 2.412a6.43 6.43 0 0 1 1.271-1.327A5.031 5.031 0 0 1 14.413 0a5.378 5.378 0 0 1 4 1.741A6.251 6.251 0 0 1 20 6.012a7.443 7.443 0 0 1-1.984 4.873 42.285 42.285 0 0 1-4.965 4.661c-.688.587-1.469 1.252-2.278 1.96a1.174 1.174 0 0 1-.773.29M5.587 1.173a4.219 4.219 0 0 0-3.139 1.363 5.085 5.085 0 0 0-1.277 3.476 6.263 6.263 0 0 0 1.714 4.125 41.619 41.619 0 0 0 4.823 4.517c.69.588 1.472 1.254 2.286 1.966.819-.713 1.6-1.381 2.293-1.969a41.627 41.627 0 0 0 4.823-4.516 6.264 6.264 0 0 0 1.714-4.125 5.085 5.085 0 0 0-1.276-3.475 4.219 4.219 0 0 0-3.139-1.364 3.885 3.885 0 0 0-2.426.84 5.685 5.685 0 0 0-1.352 1.564.74.74 0 0 1-1.27 0 5.68 5.68 0 0 0-1.348-1.563 3.885 3.885 0 0 0-2.426-.84"
                                transform="translate(.05 .049)"
                        />
                    </svg>

                    {{ item.likes }}
                </button>
            </template>
        </div>

        <div class="news-item__comments comments" v-if="item.isComment">
            <ul class="comments__list" v-if="item.comments.length">
                <li
                        class="comments__item"
                        v-for="(comment, key) in item.comments"
                        :key="key"
                >
                    <div class="comments__item-author">{{ comment.author }}</div>
                    <div class="comments__item-content" v-html="comment.content">
                    </div>
                    <time class="comments__item-date" :datetime="comment.dateCode">
                        {{ comment.date }}
                    </time>
                </li>
            </ul>

            <div class="comments__form form">
                <div class="form__group form__group--comment">
                    <label for="comment_text" class="form__label"
                    >{{ $t("common.comment") }}...</label
                    >
                    <textarea
                            @keyup="addComment"
                            type="text"
                            v-model="comment"
                            id="comment_text"
                            class="form__control"
                            name="comment_text"
                            :placeholder="$t('common.comment') + '...'"
                            rows="5"
                    ></textarea>
                </div>
                <div class="form__group form__group--file" style="display: none;">
                    <input
                            type="file"
                            name="attachment"
                            id="attachment"
                            class="form__control form__control--attach"
                    />
                    <label for="attachment" class="form__label">
                        <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="8"
                                height="17.067"
                                viewBox="0 0 8 17.067"
                        >
                            <path
                                    fill="#d3dde6"
                                    d="M139 17.067a3 3 0 0 1-3-3V4a4 4 0 1 1 8 0v10.067h-1V4a3 3 0 1 0-6 0v10.067a2 2 0 1 0 4 0V6a1 1 0 1 0-2 0v8.067h-1V6a2 2 0 1 1 4 0v8.067a3 3 0 0 1-3 3z"
                                    transform="translate(-136)"
                            />
                        </svg
                        >
                    </label>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import Radio from "../../components/elements/form/Radio";

export default {
    components: {
        Radio
    },
    props: {
        item: {
            type: Object,
            required: true,
            comments: [],
            id: null
        },
    },
    created() {
        this.liked = this.item.liked;
    },
    data() {
        return {
            liked: false,
            comment: ""
        };
    },
    methods: {
        like() {
            this.liked = !this.liked;
        },

        /**
         *
         * @param event {KeyboardEvent}
         */
        addComment(event) {
            if (event.code.toLowerCase() !== "enter" || !this.comment) {
                return;
            }
            if (event.shiftKey || event.ctrlKey) {
                return;
            }
            event.preventDefault();

            const formData = new FormData();
            formData.append("resource_id", this.$props.item.id);
            formData.append("text", this.comment);
            fetch(this.location + "/comment/add", {
                method: "POST",
                body: formData
            })
                .then(json => json.json())
                .then(data => {
                    this.item.comments.push({
                        author: data.username,
                        content: data.text,
                        dateCode: data.date,
                        date: data.date,
                        id: data.id
                    });
                    this.comment = "";
                });
        },

        vote(answerId) {
            const formData = new FormData();
            formData.append("answer_id", answerId);
            formData.append("news_id", this.item.id);

            fetch(this.location + "/news/vote", {
                method: "POST",
                body: formData
            })
                .then(data => data.json())
                .then(() => {
                    const answer = this.item.polls.answer[answerId];
                    answer.votes++;
                    this.item.polls.totalAnswer++;
                    this.item.isVote = true;
                });

        }

    },
    computed: {
        modifierTag() {
            switch (this.item.tag) {
                case "announcement":
                    return "news-item__tag--type--announcement";
                case "meeting":
                    return "news-item__tag--type--meeting";
                default:
                    return null;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base";
@import "@/assets/scss/form";

.news-item {
  &--has-comments {
    padding-bottom: 0 !important;

    .news-item__foot {
      margin: rem(0 -30px);
      padding: rem(0 30px);
      padding-top: rem(12px);
      border-top: solid 1px #d3dde6;

      @include mobile {
        padding-top: rem(14px);
      }
    }
  }

  &__tag {
    display: flex;
    align-items: center;
    margin-bottom: rem(14px);

    @include mobile {
      margin-bottom: rem(18px);
      color: #000000;
      font-weight: 500;
    }

    &::before {
      content: "";
      margin-right: rem(30px);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: block;
    }

    &--type {
      &--announcement {
        &::before {
          background-color: #f96057;
        }
      }

      &--meeting {
        &::before {
          background-color: #f8ce52;
        }
      }
    }
  }

  &__content {
    font-size: rem(14px);
    color: $black;
    line-height: 1.2;
    font-weight: 300;
    margin-bottom: rem(12px);

    p {
      margin: 0;
    }

    img {
      max-width: 50%;
      margin: 10px auto;
      display: block;
    }
  }

  &__foot {
    display: flex;
    align-items: center;
  }

  &__date {
    font-size: rem(12px);
    color: $black;
    letter-spacing: 0.12px;
  }

  &__meta {
    display: flex;
    align-items: center;
    font-size: rem(12px);

    svg {
      margin-right: rem(8px);
    }

    &--comments {
      color: #0045cb;
      margin-left: rem(92px);

      @include mobile {
        margin-left: auto;
      }
    }

    &--likes {
      color: #016fff;
      margin-left: rem(62px);

      @include mobile {
        margin-left: rem(16px);
      }
    }

    &--is-liked {
      svg path {
      }
    }
  }

  &__comments {
    margin: rem(0 -30px);
    padding: rem(12px 30px);
    margin-top: rem(12px);
    padding-bottom: rem(26px);
    background-color: rgba(#d3dde6, 0.2);
    border-top: 1px solid #d3dde6;

    @include mobile {
      margin-top: rem(14px);
      padding-top: rem(14px);
      padding-bottom: rem(30px);
    }
  }

  &__attachments {
    margin-bottom: 10px;
  }

  &__img {
    max-width: 400px;
    display: block;
  }
}

.comments {
  &__list {
    @include unlist;
  }

  &__item {
    margin-bottom: rem(16px);
    font-size: rem(14px);
    color: #3f454c;

    &-author {
      font-weight: 700;
    }

    &-content {
      margin: rem(4px 0);
    }

    &-date {
      font-size: rem(12px);
      color: #a8b9c5;
    }
  }

  &__form {
    position: relative;
    max-width: 520px;

    .form__group--comment {
      margin-bottom: 0;
    }

    .form__control {
      border: solid 1px #d3dde6;
      background-color: #ffffff;
      padding: rem(6px 10px);
      border-radius: 10px;
    }

    .form__group--file {
      position: absolute;
      right: 32px;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      margin: auto;
    }

    .vs__dropdown-toggle {
      padding: rem(10px 45px);
    }

    .vs__selected-options {
      min-width: 100px;
    }
  }
}

.news-question {
  margin-bottom: 10px;
}

.news-answer {
  margin-bottom: 5px;
}
</style>