export default {
    en: {
        common: {
            add: "Add",
            save: "Save",
            saved: "Saved",
            edit: "Edit",
            restore: "Restore",
            cancel: "Cancel",
            off: "Off",
            value: "Value",
            description: "Description",
            selected: "Selected",
            type: "Type",
            name: "Name",
            color: "Color",
            attachFile: "Attach file",
            sortByAlphabet: "Sort by alphabet",
            send: "Send",
            duplicate: "Duplicate",
            filter: "Filter",
            subject: "Subject",
            teacher: "Teacher",
            students: "Students",
            student: "Student",
            fio: "Full name",
            view: "View",
            download: "Download",
            print: "Print",
            delete: "Delete",
            comment: "Comment",
            none: "Not applicable",
            users: "Users",
            sex: "Sex",
            male: "Male",
            female: "Female",
            birthdate: "Date of birth",
            representative: "Legal representative",
            sent: "Message is sent",
            responsible: "Responsible",
            apply: "Apply",
            readDoc: "Read the document",
            readOrder: "Read the order",
            informedUsers: "Informed users",
            requiredFields: "Required fields",
            or: "Or",
            blacklist: "Blacklist user",
            auth: "Sign In",
            deleted: "Deleted",
            amount: "Amount",
            address: "Address",
            all: "All",
            openImage: "Open image",
            rows: "Limit of rows",
            fileFormat: "File extension",
            export: "Export",
            ruble: "Ruble",
            details: "Details",
            total: "Total",
            employee: "Employee",
            position: "Position",
            groupBy: "Group by",
            sort: "Sort",
            pupils: "Students",
            note: "Note",
            choose: "Choose",
            share: "Share",
            copied: "Copied",
            leaveBlank: "Don't fill to generate number",
            rub: "rub.",
            actions: "Actions"
        },
        links: {
            main: "Main",
            settings: "Settings",
            employees: "Employees",
            establishments: "Establishments",
            pupils: "Students",
            schedule: "Schedule",
            accounting: "Accounting",
            reports: "Reports",
            annual: "Annual reports",
            requests: "Requests",
            notifications: "Notifications",
            support: "Support",
            news: "News",
            clients: "Clients",
            documents: "Documents",
            affairs: "Affairs",
            tasks: "Tasks",
            deals: "Deals",
            analytics: "Analytics",
            orders: "Orders",
            menu: "Menu",
            system: "System",
            mailings: "Mailings",
            templates: "Mail templates",
            editTemplate: "Edit template",
            calendar: "Calendar",
            years: "Academic years",
            holidays: "Holidays",
            community: "Community",
            roles: "Roles",
            access: "Access",
            status: "Status",
            salary: "Salary",
            payments: "Lesson payments",
            penalty: "Penalty",
            education: "Educational",
            study: "Education",
            finances: "Finances",
            tariffs: "Tariffs",
            umk: "Educational literature",
            plugins: "Plugins",
            more: "More",
            logo: "Logo",
            blacklist: "Blacklist",
            auth: "Authorization",
            developers: "Developers",
            editList: "Edit list",
            admins: "Admins",
            profile: "Profile",
            directors: "Principals",
            managers: "Managers",
            methodists: "Methodists",
            teachers: "Teachers",
            teacherSalary: "Salary",
            fine: "Fine",
            award: "Award",
            advance: "Issue an advance",
            branches: "Branches",
            kindergartens: "Kindergartens",
            corpHome: "Corporate clients with home visit",
            classrooms: "Classrooms",
            property: "Property",
            students: "Pupils",
            certificate: "Issue a certificate",
            books: "Books",
            studentPayments: "Payments",
            import: "Import students",
            home: "Home visit",
            groups: "Groups",
            minigroups: "Mini-groups",
            individuals: "Individual students",
            corp: "Corporate clients",
            kindergroups: "Kindergarten groups",
            consHours: "Consultation hours",
            journal: "Journal",
            replacements: "Replacements",
            performance: "Performance",
            attendance: "Attendance",
            paymentsReport: "Payments",
            busyness: "Teachers busyness",
            umkReport: "Literature",
            library: "Library",
            stats: "Lessons statistics",
            refusals: "Refusals",
            expenses: "Expenses",
            history: "Work history",
            composition: "Client composition",
            funnel: "Sales funnel",
            source: "Ad source",
            earnings: "Revenue",
            annualSalary: "Year salary",
            certificates: "Certificates",
            personal: "Personal info",
            passport: "Passport",
            bank: "Bank accounts",
            widgets: "Widgets",
            search: "Search",
            memory: "Memory",
            "404": "404",
            "403": "403",
            add: "Add",
            edit: "Edit",
            archive: "Archive",
            efficiency: "Efficiency",
            gave: "Issue wages",
            calls: "Calls",
            salesPlan: "Sales plan"
        },
        modal: {
            confirm: "Are you sure?",
            yes: "Yes",
            no: "No",
            show: "Show",
            debts: "There are students debts:"
        },
        profile: {
            account: "Profile",
            logOut: "Log out",
            logOutConfirmation: "Are you sure you want to log out?",
            name: "First name",
            login: "Login",
            lastname: "Last name",
            surname: "Middle name",
            phone: "Phone",
            password: "New password",
            repeatPassword: "Repeat password",
            passwordError: "Passwords aren't the same",
            emailTaken: "This email is already taken",
            loginTaken: "This login is already taken",
            personal: "Personal data",
            lastTime: "Last log in time",
            qualification: "Qualification",
            active: "Activated",
            notActive: "Not activated",
            registerDate: "Date of registration",
            trial: "Trial",
            balance: "Balance",
            pay: "Pay"
        },
        calendar: {
            monday: "Monday",
            mondayShort: "Mon",
            tuesday: "Tuesday",
            tuesdayShort: "Tues",
            wednesday: "Wednesday",
            wednesdayShort: "Wed",
            thursday: "Thursday",
            thursdayShort: "Thurs",
            friday: "Friday",
            fridayShort: "Fri",
            saturday: "Saturday",
            saturdayShort: "Sat",
            sunday: "Sunday",
            sundayShort: "Sun",
            year: "Year",
            month: "Month",
            week: "Week",
            day: "Day",
            weekDay: "Week day",
            addEvent: "Add event",
            editEvent: "Edit event",
            dateStart: "Start date",
            dateEnd: "End date",
            time: "Time",
            minutes: "Minutes",
            chooseDay: "Choose day",
            lessonDuration: "Lesson duration",
            date: "Date",
            range: "Range",
            from: "From",
            to: "To",
            workTime: "Work time",
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December",
            annual: "Annual",
            monthly: "Monthly",
            deadline: "Deadline",
            hours: "Hours"
        },
        settings: {
            autoLogOutTime: "Time of auto log out (min)",
            smsConfirmation: "SMS confirmation upon sign in",
            editCapacity: "Edit capacity",
            lessonInfo: "Lesson information",
            editMailTemplate: "Edit mail template",
            mailTitle: "Mail title",
            mailBody: "Mail body",
            notification: "Notification",
            paymentProcess: "Payment process for groups",
            lessonsCount: "Count of lessons",
            considerSick: "Consider absence due to illness",
            considerSickTip: "If to choose 's'/was sick upon filling attendance, payment for skipped lesson won't be deducted from student balance and will be prolonged to next lessons",
            blacklistConfirm: "Are you sure you want to blacklist the user?",
            smsDuration: "Once/regular",
            insertVar: "Insert variables",
            sender: "Sender",
            addTemplate: "Add template",
            once: "Once",
            regular: "Regular",
            recipients: "Recipients",
            archive: "Archive",
            categories: "Categories",
            penaltyDay: "Day of penalty",
            penaltyPercent: "Percentage of lesson salary, %",
            penaltyFirst: "From original salary",
            penaltyLast: "From last penalty",
            lessonDay: "Day from held lesson",
            short: "Short name",
            authForm: "Auth form",
            authFormDescription: "Let sign in CRM on any site",
            free: "Free",
            install: "Install",
            uninstall: "Uninstall",
            installed: "Installed",
            allPlugins: "All plugins"
        },
        journal: {
            theme: "Lesson topic",
            target: "Lesson purpose",
            purpose: "Tasks to achieve the purpose",
            homework: "Homework",
            classroom: "Classroom",
            filesTip: "You can upload no more than 3 files",
            filesSizeTip: "You can upload no more than 3 files no larger than",
            payment: "Payment",
            notPaid: "Lesson isn't paid",
            paid: "Lesson is paid",
            confirm: "Verified payment",
            attendance: "Attendance",
            attended: "Attended",
            attendedShort: "+",
            absent: "Was absent",
            absentShort: "Ab",
            sick: "Was sick",
            sickShort: "S",
            late: "Was late",
            lateShort: "L",
            skip: "Skipped",
            skipShort: "Sk",
            reason: "Valid reason for absence",
            reasonShort: "R",
            rate: "Grade",
            oneLesson: "lesson",
            status: "Status",
            showLessons: "Show lessons",
            salary: "Salary",
            add: "Add new lesson",
            type: "Lesson type",
            category: "Lesson category",
            editAttendance: "Edit attendance",
            cancel: "Reschedule/cancel",
            cancelCancel: 'Cancel "reschedule/cancel"',
            dateError: "Chosen date is already on schedule",
            replacer: "Substitute",
            replaceDateError: "Choose date from the schedule",
            practical: "Practical",
            test: "Test",
            speaking: "Speaking",
            grammar: "Grammar",
            reading: "Reading",
            writing: "Writing",
            listening: "Listening",
            reschedule: "Reschedule",
            cancellation: "Cancellation",
            scheduleError: "Fill out schedule for the group",
            lessonsError: "No lessons available",
            teachersError: "No teachers/groups",
            classroomMax: "Max amount of students",
            classroomError: "Groups with more students accommodate in the classroom. For reducing allowed amount of students it's recommended to change classroom for the groups"
        },
        events: {
            affair: "Affair",
            call: "Call",
            meeting: "Meeting",
            conference: "Conference",
            holiday: "Holiday"
        },
        search: {
            search: "Search",
            result: "Rows"
        },
        charts: {
            type: "Chart type",
            column: "Столбиковая диаграмма",
            pie: "Круговая диаграмма",
            columnPolar: "Column polar",
            graph: "Graph",
            funnel: "Funnel"
        },
        tables: {
            table: "Table",
            setup: "Table setup"
        },
        groups: {
            phone: "Home phone number",
            level: "Level",
            category: "Students category",
            type: "Group type",
            max: "Maximum amount of students",
            closed: "Closed",
            smiles: "Show emojis",
            studentDate: "Addition date",
            createNotification: "Notify teacher about group creation",
            newStudentNotification: "Notify teacher about new student",
            capacityError: "You can't add more students to the group because classroom doesn't accommodate so many students. Try changing classroom for the group and then add students",
            address: "Student address",
            kindergarten: "Kindergarten",
            branch: "Branch",
            group: "Group",
            scheduleTip: `Upon creating schedule, you must:<br>
1). Fill date range "from" - "to" date it's valid<br>
2). Select classroom<br>
3). Select time in calendar<br>
<br>
Note: there are rules when editing schedule:<br>
🔹 You can't edit schedule for past days. This is logical because lessons have been already held on previously selected time<br>
🔹 You can edit schedule if you choose "WEEK"<br>
🔹 You can change schedule only for future days, lessons that haven't started yet. You must:<br>
✅ Choose date range in "week" mode<br>
You can't edit anything for this week, so first you must limit date range. For this, don't change start date, but change end date of schedule, for example: Last day of week is sunday 23th, thus end date in range is 23th. Then we make sure to click save.<br>
✅ Create new schedule for next "week"<br>
After page-refresh, choose next week in calendar, now monday is 24th. Lessons disappeared for this week after you change past date range. For editing you must choose new date range, choose 24th as start date(for example) and end date is whatever you want, choose classroom asn fill lessons.<br>
<br>
and remember:<br>
▶️if you want to change schedule for current week, you can add unscheduled lesson in journal or cancel it. But starting next week you should create new schedule<br>
<br>
Now schedule adjusted and works starting next week👌`,
            deleteReason: "Reason to remove student"
        },
        schedule: {
            weekDayError: "Date range must start on Monday and end on Sunday",
            dateError: "Start date must be less than or equal to end date",
            pastDateError: "Schedule can't be edited for past date",
            takenRange: "Selected date range is already taken",
            capacityError: "You can't accommodate the group in the classroom because amount of students exceed maximum allowed \n Try choosing another classroom for the group"
        },
        bank: {
            cardNumber: "Card number",
            cardOwner: "Card owner",
            cardAccount: "Bank account",
            bank: "Bank",
            bik: "BIK",
            kpp: "KPP",
            inn: "INN",
            okpo: "OKPO",
            ogrn: "OGRN",
            legalAddress: "Legal address",
            postAddress: "Post address",
            postAddressAdditional: "Additional post address"
        },
        passport: {
            passportSeries: "Passport series",
            passportId: "Passport number",
            passportDate: "Issue date",
            passportOffice: "Issuer",
            passportOfficeCode: "Issuer code"
        },
        consult: {
            description: "Note",
            view: "Viewing consultation hour"
        },
        deals: {
            source: "Source",
            profile: "Profile data",
            transfer: "Transfer to students",
            address: "Address",
            status: "Deal status",
            call: "Call",
            refuse: "Refuse the deal",
            refuseReason: "Refusal reason",
            invite: "Invite to trial lesson",
            chooseTemplate: "Choose template",
            pluginError: "Install plugin for sending SMS",
            action: "Choose action",
            languageError: "Chosen deals have different subjects",
            levelError: "Chosen deals have different levels",
            createGroup: "Create group"
        },
        priorities: {
            priority: "Приоритет",
            high: "Высокий",
            middle: "Middle",
            low: "Низкий"
        },
        students: {
            importSetting: "Import setup",
            totalRow: "Total rows:",
            skipRow: "Skip rows",
            skipRowTip: "Number of rows to skip",
            parentPhone: "Representative's phone",
            rowsError: "Errors in rows:",
            importedRows: "Imported rows:",
            drag: "Drag file here",
            uploadMax: "Max file size is 100MB. Supported extensions: XLS",
            example: "File example",
            paymentNumber: "Payment NO.",
            confirm: "Confirmed",
            notConfirm: "Not confirmed",
            addFriend: "Add friend",
            paymentDateError: "Payment for chosen date range already exists",
            certNumber: "Cert NO.",
            startEducation: "Start date of studying",
            endEducation: "End date of studying",
            certFio: "Full name of person issuing certificate",
            chooseFields: "Choose fields",
            fieldError: "Choose at least one field",
            formatError: "Choose in which file extension to export",
            educationInfo: "Education info",
            discount: "Discount",
            discountReason: "Discount reason",
            friendsList: "Friends list",
            contract: "Contract",
            debtNotify: "Notify about debt every hour",
            froFriend: "For referring a friend",
            dealTransfer: "Transfer to deals",
            bind: "Bind to",
            bindTip: "If representative has several students, you can bind them to sign in by common login/password",
            chooseStudents: "Choose students",
            bindError: "Selected students are already bound to another representative",
            transferred: "Transferred",
            applyConsult: "Apply for a consultation hour",
            studyPeriod: "Period of study",
            timeError: "Chosen date is already taken",
            wrongDate: "Wrong date",
            applyError: "You've already applied",
            studentsError: "Choose students for export"
        },
        employees: {
            editConsult: "Edit schedule of consultation hour",
            working: "Working",
            notWorking: "Not working"
        },
        property: {
            number: "Number",
            cost: "Price",
            author: "Author",
            publisher: "Publisher",
            inventory: "Inventory",
            inStock: "In stock",
            outStock: "Out of stock",
            inventoryData: "Inventory data",
            stock: "Stock",
            furniture: "Furniture",
            books: "Educational books",
            tools: "Technical tools",
            review: "Inventory",
            reviewInfo: "Review info",
            issued: "Issued",
            notIssued: "Not issued",
            issuance: "Issuance",
            delivered: "Delivered",
            paid: "Paid",
            paymentConfirm: "Payment confirmation",
            buy: "Buy",
            bought: "Purchased",
            count: "Count",
            paidSuccess: "Successful payment"
        },
        news: {
            comments: "Enable comment",
            survey: "Survey",
            question: "Question",
            answer: "Answer"
        },
        reports: {
            studentsCount: "Amount of students",
            lessonsCount: "Amount of lessons",
            lessonsWeek: "Amount of lessons per week",
            lessonsMonth: "Amount of lessons per month",
            lessonsYear: "Amount of lessons per year",
            lessonsTotal: "Total amount of lessons",
            category: "Category",
            expenseItem: "Expense item",
            expensesSum: "Total amount of expenses",
            expenses: "Expenses",
            handed: "Handed over",
            lessonSalary: "Salary per lesson",
            totalSalary: "Total salary",
            journalPenalty: "Journal penalty",
            salary: "Salary",
            advance: "Advance",
            award: "Award",
            penalty: "Penalty",
            paid: "Paid",
            lessonsPlan: "Amount of lessons (schedule)",
            lessonsPlanTip: "Amount of lessons that will be held during academic year according to filled schedule",
            lessonsFact: "Held lessons (fact)",
            lessonsFactTip: "Amount of held lessons with individual students or group student at this moment, lessons that is subject of payment and were paid",
            lessonsPaid: "Amount of paid lessons (at this moment)",
            lessonsPaidTip: "Amount of lessons student paid for at this moment studying in group or individually",
            freeBalance: "Free balance (lessons)",
            freeBalanceTip: "Amount of free left lessons",
            freeBalanceRub: "Free balance (rub.)",
            freeBalanceRubTip: "Amount of free money remaining on balance",
            lessonsUnpaid: "Amount of unpaid lessons (at this moment)",
            lessonsUnpaidTip: "Debt! Amount of lessons student attended but didn't pay for",
            lessonsUnpaidTotal: "Amount of unpaid lessons (until the end of academic year)",
            lessonsUnpaidTotalTip: "Amount of unpaid lessons until the end of academic year/period according to schedule of individual student or group",
            unscheduledLessons: "Unscheduled lessons",
            unscheduledLessonsTip: "Unscheduled held lessons",
            lessonsAttend: "Amount of lessons attended",
            lessonsAttendTip: "Amount of lessons student attended",
            lessonsCanceled: "Amount of canceled lessons",
            lessonsCanceledTip: "Amount of lessons canceled by branch on its own initiative, for example, holidays or individual lessons canceled by student at their request according to contract. Payment isn't deducted from balance",
            lessonsTotalTip: "Total amount of held lessons including unscheduled ones and excluding canceled ones",
            considerDeleted: "Consider deleted",
            averagePayment: "Average payment",
            lessonCancellation: "Cancellation of classes",
            studentReason: "Students have reason",
            sick: "Students are sick",
            directorsAmount: "Amount of principal",
            managersAmount: "Amount of managers",
            teachersAmount: "Amount of teachers",
            corpAmount: "Amount of students in corp groups",
            corpHomeAmount: "Amount of students in crop groups with home visit",
            groupAmount: "Amount of students in groups",
            kindergartenAmount: "Amount of students in kindergartens",
            homeAmount: "Amount of students with home visit",
            minigroupAmount: "Amount of student in mini-groups"
        },
        requests: {
            dealTransfer: "Transfer to deals",
            studentTransfer: "Transfer to student transfer",
            test: "Exam"
        },
        salary: {
            from: "From",
            to: "To",
            studentsFrom: "Amount of students (from)",
            studentsTo: "Amount of students (to)",
            students: "Amount of students",
            salary: "Salary",
            perLesson: "Per lesson",
            perStudent: "Per student",
            studentsError: "Salary with selected amount of students is already set. Try changing amount for new salary or changing amount for previous one",
            recalculate: "Recalculate salary",
            zeroLesson: "Only lessons with 0 salary",
            recalculated: "Lessons recalculated"
        },
        tasks: {
            taskDescription: "Description",
            customer: "Customer",
            type: "Type",
            responsibleCategory: "Category of responsible employees",
            collaboratorCategory: "Category of collaborators",
            collaborators: "Collaborators",
            burn: "Urgent",
            notify: "Reminder",
            every: "Every",
            workDay: "Only work day",
            repeat: "Repeat",
            bonus: "Bonus",
            editStatus: "Edit status",
            new: "New",
            process: "In process",
            completed: "Completed",
            notCompleted: "Not completed",
            addDesk: "Add column",
            editDesk: "Edit column",
            move: "Move",
            orderSaved: "Order is saved"
        },
        mail: {
            inbox: "Inbox",
            sent: "Sent",
            favourite: "Favourite",
            archived: "Archived",
            deleted: "Deleted",
            read: "Read",
            notRead: "New",
            reply: "Reply",
            replyAll: "Reply to all",
            forward: "Forward",
            to: "to",
            addRecipients: "Add recipients",
            new: "New message",
            title: "Title",
            deleteDraft: "Delete draft"
        },
        support: {
            openChat: "Open chat",
            sent: "Your request has been sent",
            section: "Section",
            type: "Type message"
        }
    },
    ru: {
        common: {
            add: "Добавить",
            save: "Сохранить",
            saved: "Сохранено",
            edit: "Редактировать",
            restore: "Восстановить",
            cancel: "Отменить",
            off: "Выкл.",
            value: "Значение",
            description: "Описание",
            selected: "Выбрано",
            type: "Тип",
            name: "Название",
            color: "Цвет",
            attachFile: "Прикрепить файл",
            sortByAlphabet: "Сортировать по алфавиту",
            send: "Отправить",
            duplicate: "Дублировать",
            filter: "Фильтр",
            subject: "Предмет",
            teacher: "Преподаватель",
            students: "Ученики",
            student: "Ученик",
            fio: "ФИО",
            view: "Подробнее",
            download: "Сохранить",
            print: "Распечатать",
            delete: "Удалить",
            comment: "Комментарий",
            none: "Не указано",
            users: "Пользователи",
            sex: "Пол",
            male: "Мужской",
            female: "Женский",
            birthdate: "Дата рождения",
            representative: "Законный представитель",
            sent: "Сообщение отправлено",
            responsible: "Ответственный",
            apply: "Применить",
            readDoc: "Ознакомиться с документом",
            readOrder: "Ознакомиться с приказом",
            informedUsers: "Ознакомлены с приказом",
            requiredFields: "Обязательные поля",
            or: "Или",
            blacklist: "Внести в черный список",
            auth: "Авторизоваться",
            deleted: "Удаленные",
            amount: "Сумма",
            address: "Адрес",
            all: "Все",
            openImage: "Открыть изображение",
            rows: "Количество записей",
            fileFormat: "Формат файла",
            export: "Экспорт",
            ruble: "Рубль",
            details: "Подробнее",
            total: "Итого",
            employee: "Сотрудник",
            position: "Должность",
            groupBy: "Группировать по",
            sort: "Сортировка",
            pupils: "Учащиеся",
            note: "Прим.",
            choose: "Выбрать",
            share: "Поделиться",
            copied: "Скопировано",
            leaveBlank: "Оставьте пустым для автозаполнения",
            rub: "руб.",
            actions: "Действия"
        },
        links: {
            main: "Главная",
            settings: "Настройки",
            employees: "Сотрудники",
            establishments: "Учебные заведения",
            pupils: "Учащиеся",
            schedule: "Расписание",
            accounting: "Учетность",
            reports: "Отчеты",
            annual: "Годовые отчеты",
            requests: "Заявки",
            notifications: "Уведомления",
            support: "Поддержка",
            news: "Новости",
            clients: "Абоненты",
            documents: "Документы",
            affairs: "Дела",
            tasks: "Задачи",
            deals: "Сделки",
            analytics: "Аналитика",
            orders: "Приказы",
            menu: "Меню",
            system: "Системные",
            mailings: "Рассылки",
            templates: "Шаблоны писем",
            editTemplate: "Редактировать шаблон",
            calendar: "Производственный календарь",
            years: "Учебные года",
            holidays: "Праздники",
            community: "Комьюнити",
            roles: "Роли",
            access: "Доступы",
            status: "Статус",
            salary: "Зарплата",
            payments: "Оплата занятий",
            penalty: "Штрафы",
            education: "Учебный процесс",
            study: "Обучение",
            finances: "Финансы",
            tariffs: "Абонементы",
            umk: "УМК",
            plugins: "Интеграции",
            more: "Прочее",
            logo: "Логотип филиала",
            blacklist: "Черный список",
            auth: "Авторизация",
            developers: "Разработчикам",
            editList: "Редактировать список",
            admins: "Администраторы",
            profile: "Профиль",
            directors: "Директоры",
            managers: "Менеджеры",
            methodists: "Методисты",
            teachers: "Преподаватели",
            teacherSalary: "Начисление зарплаты",
            fine: "Оштрафовать",
            award: "Наградить премией",
            advance: "Выдать аванс",
            branches: "Филиалы",
            kindergartens: "Детские сады",
            classrooms: "Аудитории",
            property: "Имущество",
            students: "Ученики",
            certificate: "Выдать свидетельство",
            books: "Учебники",
            studentPayments: "Платежи",
            import: "Импорт учеников",
            corpHome: "Корпоративные клиенты с выездом",
            home: "Выезд на дом",
            groups: "Группы",
            minigroups: "Мини-группы",
            individuals: "Индивидуальные ученики",
            corp: "Корпоративные клиенты",
            kindergroups: "Группы детских садов",
            consHours: "Консультационные часы",
            journal: "Журнал",
            replacements: "Замены",
            performance: "Успеваемость",
            attendance: "Посещаемость",
            paymentsReport: "Оплата",
            busyness: "Занятость преподавателей",
            umkReport: "Заказы УМК",
            library: "Библиотека",
            stats: "Статистика по занятиям",
            refusals: "Отказы",
            expenses: "Расходы",
            history: "История работы",
            composition: "Состав клиентов",
            funnel: "Воронка продаж",
            source: "Источник рекламы",
            earnings: "Выручка",
            annualSalary: "Годовая зарплата",
            certificates: "Свидетельства",
            personal: "Личные данные",
            passport: "Паспортные данные",
            bank: "Банковские реквизиты",
            widgets: "Виджеты",
            search: "Поиск",
            memory: "Счетчик памяти",
            "404": "404",
            "403": "403",
            add: "Добавить",
            edit: "Редактировать",
            archive: "Архив",
            efficiency: "Рентабельность",
            gave: "Выдать зарплату",
            calls: "Звонки",
            salesPlan: "План продаж",
        },
        modal: {
            confirm: "Вы уверены?",
            yes: "Да",
            no: "Нет",
            show: "Просмотреть",
            debts: "Имеются долги по ученикам:"
        },
        profile: {
            account: "Личный кабинет",
            logOut: "Выйти",
            logOutConfirmation: "Вы уверены, что хотите выйти?",
            name: "Имя",
            login: "Логин",
            lastname: "Фамилия",
            surname: "Отчество",
            phone: "Телефон",
            password: "Новый пароль",
            repeatPassword: "Подтверждение пароля",
            passwordError: "Пароли не совпадают",
            emailTaken: "Данный email уже занят",
            loginTaken: "Данный логин уже занят",
            personal: "Личные данные",
            lastTime: "Последний вход",
            qualification: "Квалификация",
            active: "Активно",
            notActive: "Не аткивно",
            registerDate: "Дата регистрации",
            trial: "Тестовый период",
            balance: "Баланс",
            pay: "перейти к оплате"
        },
        calendar: {
            monday: "Понедельник",
            mondayShort: "Пн",
            tuesday: "Вторник",
            tuesdayShort: "Вт",
            wednesday: "Среда",
            wednesdayShort: "Ср",
            thursday: "Четверг",
            thursdayShort: "Чт",
            friday: "Пятница",
            fridayShort: "Пт",
            saturday: "Суббота",
            saturdayShort: "Сб",
            sunday: "Воскресенье",
            sundayShort: "Вс",
            year: "Год",
            month: "Месяц",
            week: "Неделя",
            day: "День",
            weekDay: "День недели",
            addEvent: "Добавить мероприятие",
            editEvent: "Редактировать мероприятие",
            dateStart: "Начало",
            dateEnd: "Окончание",
            time: "Время",
            minutes: "Минуты",
            chooseDay: "Выбрать день",
            lessonDuration: "Длительность занятий",
            date: "Дата",
            range: "Период",
            from: "С",
            to: "До",
            workTime: "Время работы",
            january: "Январь",
            february: "Февраль",
            march: "Март",
            april: "Апрель",
            may: "Май",
            june: "Июнь",
            july: "Июль",
            august: "Август",
            september: "Сентябрь",
            october: "Октябрь",
            november: "Ноябрь",
            december: "Декабрь",
            annual: "За год",
            monthly: "За месяц",
            deadline: "Дедлайн",
            hours: "Часы"
        },
        settings: {
            autoLogOutTime: "Период автоматического выхода (мин)",
            smsConfirmation: "Авторизация с подтверждением по SMS",
            editCapacity: "Редактировать вместимость",
            lessonInfo: "Информация о занятии",
            editMailTemplate: "Редактировать шаблон письма",
            mailTitle: "Заголовок письма",
            mailBody: "Содержание",
            notification: "Оповещение",
            paymentProcess: "Система оплаты для групп",
            lessonsCount: "Количество занятий",
            considerSick: "Учитывать пропуски по болезням",
            considerSickTip: "При учете посещаемости и выставлении 'б'/болезнь оплата за пропущенные уроки по болезни не будет списываться с баланса ученика и будет переноситься / пролонгироваться на последующие занятия",
            blacklistConfirm: "Вы уверены, что хотите внести внести данного пользователя в черный список?",
            smsDuration: "Разовая/постоянная",
            insertVar: "Вставить переменные",
            sender: "Отправитель",
            addTemplate: "Добавить шаблон письма",
            once: "Разовая",
            regular: "Регулярная",
            recipients: "Получатели",
            archive: "Архив рассылок",
            categories: "Категории",
            penaltyDay: "На какой день штрафовать",
            penaltyPercent: "Процент от стоимости  занятия, %",
            penaltyFirst: "От первоначальной стоимости занятия",
            penaltyLast: "От предыдущего штрафа",
            lessonDay: "день от проведенного занятия",
            short: "Сокращенное обозначение",
            authForm: "Форма авторизации",
            authFormDescription: "Позволяет войти в CRM на любом сайте",
            free: "Бесплатно",
            install: "Установить",
            uninstall: "Удалить",
            installed: "Установленные",
            allPlugins: "Все приложения"
        },
        journal: {
            theme: "Тема урока",
            target: "Цель урока",
            purpose: "Задачи для достижения цели",
            homework: "Домашнее задание",
            classroom: "Аудитория",
            filesTip: "Вы можете загрузить не более 3х файлов",
            filesSizeTip: "Вы можете загрузить не более 3х файлов объемом не более",
            payment: "Оплата",
            notPaid: "Занятие не оплачено",
            paid: "Занятие оплачено",
            confirm: "Подтверждение оплаты",
            attendance: "Посещаемость",
            attended: "Присутствовал",
            attendedShort: "+",
            absent: "Отсутствовал",
            absentShort: "Н",
            sick: "Болел",
            sickShort: "Б",
            late: "Опоздал",
            lateShort: "О",
            skip: "Прогулял",
            skipShort: "П",
            reason: "Уважительная причина отсутствия",
            reasonShort: "У",
            rate: "Оценка",
            oneLesson: "1 занятия",
            status: "Статус",
            showLessons: "Показать занятий",
            salary: "Начислено",
            add: "Добавить новое занятие",
            type: "Тип занятия",
            category: "Категория занятия",
            editAttendance: "Редактировать посещаемость",
            cancel: "Перенос/отмена",
            cancelCancel: 'Отменить "отмену/перенос"',
            dateError: "Выбранная дата уже есть в расписании",
            replacer: "Заменяющий",
            replaceDateError: "Выберите дату из расписания",
            practical: "Практическая",
            test: "Контрольная",
            speaking: "Говорение",
            grammar: "Лексико-грамматические навыки",
            reading: "Чтение",
            writing: "Письмо",
            listening: "Аудирование",
            reschedule: "Перенос",
            cancellation: "Отмена",
            scheduleError: "Заполните расписание у данной группы",
            lessonsError: "Нет доступных занятий",
            teachersError: "Нет преподавателей/групп",
            classroomMax: "Максимальное количество учащихся",
            classroomError: "В данной аудитории учатся группы с бОльшим количеством учащихся. Для уменьшения максимального количества учащихся данной аудитории рекомендуется перенести уже занимающиеся в этой аудитории группы в другие аудитории"
        },
        events: {
            affair: "Дело",
            call: "Звонок",
            meeting: "Встреча",
            conference: "Совещание",
            holiday: "Праздник"
        },
        search: {
            search: "Поиск",
            result: "Результатов"
        },
        charts: {
            type: "Тип диаграммы",
            column: "Столбиковая диаграмма",
            pie: "Круговая диаграмма",
            columnPolar: "Круговая диаграмма 2",
            graph: "График",
            funnel: "Воронка"
        },
        tables: {
            table: "Таблица",
            setup: "Настройка таблицы"
        },
        groups: {
            phone: "Домашний номер телефона",
            level: "Уровень подготовки",
            category: "Категория учащихся",
            type: "Тип группы",
            max: "Максимальное количество учащихся",
            closed: "Закрытая",
            smiles: "Показывать смайлы",
            studentDate: "Дата добавления",
            createNotification: "Уведомить преподавателя о создании группы",
            newStudentNotification: "Уведомить преподавателя о новом учащемся",
            capacityError: "Вы не можете добавить еще учащихся в данную группу, так как вместимость аудитории не позволяет этого сделать. Попробуйте сначала изменить аудиторию для этой группы, а затем добавьте учащихся",
            address: "Адрес учащегося",
            kindergarten: "Детский сад",
            branch: "Филиал",
            group: "Группа",
            scheduleTip: `При формировании расписания необходимо:<br>
1). выставить период "с" - "до" какой даты оно действительно<br>
2). выбрать аудиторию<br>
3). выбрать время занятия в сетке расписания<br>
<br>
Обратите внимание на правила составления и редактирования расписания:<br>
🔹 редактировать расписание задним числом нельзя. Это логично, т.к. занятия уже проведены в ранее утвержденные дни и время.<br>
🔹 редактировать и изменять раписание можно в сетке при выборе "НЕДЕЛЯ"<br>
🔹 Изменить можно только будущее расписание, занятия, которые еще не наступили. Для этого нужно:<br>
✅ ограничить дату текущего расписания на "неделе"<br>
На текущей неделе уже редактировать ничего нельзя, поэтому первое что мы делаем выставляем ограничения в периоде. Для этого мы не меняем дату начала, а меняем дату окончания расписания, например: у вас последний день на этой неделе воскресенье 23 число, значит дата окончания в периоде 23. Далее мы обязательно нажимаем сохранить.<br>
✅ создать новое расписание на следующей "неделе"<br>
После автоматического обновления странички, мы перелистываем расписание на следующую неделю, теперь у вас на странице понедельник 24 число. Занятия автоматически убрались на новой неделе в данной группе после того, как вы изменили период в прошлом. Для корректировки меняем период уже на странице с новым расписанием мы ставим дату начала 24 число (к примеру) а дату окончания какую Вам удобно, выбираем аудиторию, и выставляем уроки.<br>
<br>
и еще важно помнить:<br>
▶️если Вы захотите поменять расписание на текущей неделе, то Вы можете либо добавить внеплановый урок в журнале либо отменить его. А вот со следующей недели мы уже создаем новое расписание.<br>
<br>
Теперь расписание скорректировано и начинает действовать со следующей недели👌`,
            deleteReason: "Причина удаления учащегося"
        },
        schedule: {
            weekDayError: "Период расписания должен начинаться в понедельник и заканчиваться в воскресенье",
            dateError: "Дата начала расписания должна быть меньше/равна дате конца",
            pastDateError: "Расписание нельзя редактировать на прошедшие даты",
            takenRange: "Выбранное время уже занято",
            capacityError: "Вы не можете разместить эту группу в данной аудитории, так как количество учащихся в ней превышает максимально допустимую вмещаемость \n Попробуйте выбрать другую аудиторию для этой группы"
        },
        bank: {
            cardNumber: "Номер карты",
            cardOwner: "Держатель карты",
            cardAccount: "Номер счета карты",
            bank: "Банк получатель",
            bik: "Бик",
            kpp: "КПП",
            inn: "ИНН",
            okpo: "ОКПО",
            ogrn: "ОГРН",
            legalAddress: "Юридический адрес банка",
            postAddress: "Почтовый адрес банка",
            postAddressAdditional: "Почтовый адрес доп офиса"
        },
        passport: {
            passportSeries: "Серия паспорта",
            passportId: "Номер паспорта",
            passportDate: "Дата выдачи",
            passportOffice: "Выдан",
            passportOfficeCode: "Код подразделения"
        },
        consult: {
            description: "Пожелания",
            view: "Просмотр консультационного часа"
        },
        deals: {
            source: "Источник получение",
            profile: "Личные данные",
            transfer: "Перенести в учащиеся",
            address: "Адрес проживания",
            status: "Статус сделки",
            call: "Перезвонить",
            refuse: "Отказ от сделки",
            refuseReason: "Причина отказа",
            invite: "Пригласить на пробное занятие",
            chooseTemplate: "Выбрать шаблон",
            pluginError: "Установите плагин для отправки SMS",
            action: "Выберите действие",
            languageError: "Выбранные сделки имеют разные предметы",
            levelError: "Выбранные сделки имеют разные уровни",
            createGroup: "Сформировать группу"
        },
        priorities: {
            priority: "Приоритет",
            high: "Высокий",
            middle: "Средний",
            low: "Низкий"
        },
        students: {
            importSetting: "Настройка импорта контактов",
            totalRow: "Всего строк:",
            skipRow: "Пропустить строк",
            skipRowTip: "Количество строк, которые нужно пропустить",
            parentPhone: "Телефон представителя",
            rowsError: "Ошибки в строках:",
            importedRows: "Импортировано строк:",
            drag: "Перетащите файл сюда",
            uploadMax: "Максимальный размер загружаемого файла 100 MB. Поддерживаемые форматы: XLS",
            example: "Пример файла",
            paymentNumber: "№ квит.",
            confirm: "Подтвержден",
            notConfirm: "Не подтвержден",
            addFriend: "Добавить друга",
            paymentDateError: "Платеж на выбранные даты уже существует",
            certNumber: "№ сертификата",
            startEducation: "Старт обучения",
            endEducation: "Окончание обучение",
            certFio: "ФИО, выдавшего свидетельство",
            chooseFields: "Выбрать поля",
            fieldError: "Выберите хотя бы одно поле для выгрузки",
            formatError: "Выберите в каком формате сделать выгрузку",
            educationInfo: "Параметры обучения",
            discount: "Скидка",
            discountReason: "Обоснование скидки",
            friendsList: "Список друзей",
            contract: "Наличие договора",
            debtNotify: "Уведомлять о задолженности каждый час",
            forFriend: "За приведенного друга",
            dealTransfer: "Перенести в сделки",
            bind: "Связать с",
            bindTip: "Если у одного законного представителя обучается несколько учащихся, то вы можете их связать для входа в CRM под одним логином/паролем",
            chooseStudents: "Выберите учеников",
            bindError: "Выбранные ученики уже связаны с другим представителем",
            transferred: "Перенесено",
            applyConsult: "Запись на консультационный час",
            studyPeriod: "Период обучения",
            timeError: "Данное время уже занято",
            wrongDate: "Неверная дата",
            applyError: "Вы уже записались",
            studentsError: "Выберите список учеников для выгрузки"
        },
        employees: {
            editConsult: "Редактирование расписания консультационного часа",
            working: "Работает",
            notWorking: "Не работает"
        },
        property: {
            number: "Номер",
            cost: "Стоимость",
            author: "Автор",
            publisher: "Издательство",
            inventory: "Опись",
            inStock: "В наличии",
            outStock: "Отсутствует",
            inventoryData: "Данные проверки",
            stock: "Наличие",
            furniture: "Мебель",
            books: "Учебно-методическая литература",
            tools: "Технические средства обучения",
            review: "Выполнить проверку",
            reviewInfo: "Данные проверки",
            issued: "Выдано",
            notIssued: "Не выдано",
            issuance: "Выдача",
            delivered: "Доставлено",
            paid: "Оплачено",
            paymentConfirm: "Подтверждение оплаты",
            buy: "Купить",
            bought: "Закуплено",
            count: "Кол-во",
            paidSuccess: "Оплата прошла"
        },
        news: {
            comments: "Разрешить комментарии",
            survey: "Опрос",
            question: "Вопрос",
            answer: "Ответ"
        },
        reports: {
            studentsCount: "Кол-во учеников",
            lessonsCount: "Кол-во занятий",
            lessonsWeek: "Кол. занятий в неделю",
            lessonsMonth: "Кол. занятий в месяц",
            lessonsYear: "Кол. занятий в год",
            lessonsTotal: "Всего проведено занятий",
            category: "Категория",
            expenseItem: "Статья расходов",
            expensesSum: "Общая сумма расходов",
            expenses: "Расходы",
            handed: "Сдано",
            lessonSalary: "З/П за 1 занятие",
            totalSalary: "З/П общая",
            journalPenalty: "Штраф за<br>учетность",
            salary: "Зарплата",
            advance: "Аванс",
            award: "Премия",
            penalty: "Штраф",
            paid: "Выдано",
            lessonsPlan: "Кол-во занятий (план)",
            lessonsPlanTip: "Количество занятий, которое должно пройти за учебный год согласно составленному расписанию",
            lessonsFact: "Кол-во проведенных занятий (факт)",
            lessonsFactTip: "Количество проведенных занятий у индивидуального учащегося или ученика в группе на текущий момент, занятия которых подлежат оплате и баланс был списан",
            lessonsPaid: "Кол-во оплаченных занятий (на текущий момент)",
            lessonsPaidTip: "Количество занятий, за которые учащийся оплатил на текущий момент времени в группе или занимаясь индивидуально",
            freeBalance: "Свободный баланс (занятия)",
            freeBalanceTip: "Количество оставшихся свободных занятий",
            freeBalanceRub: "Свободный баланс (руб.)",
            freeBalanceRubTip: "Количество оставшихся на балансе денежных средств",
            lessonsUnpaid: "Кол-во неоплаченных занятий (на текущий момент)",
            lessonsUnpaidTip: "Долг! Количество занятий, на которых учащийся уже присутствовал, но не оплатил",
            lessonsUnpaidTotal: "Кол-во неоплаченных занятий (до конца учебного периода)",
            lessonsUnpaidTotalTip: "Количество неоплаченных занятий до конца учебного года/периода в соответствии с индивидуальным графиком расписания индивидуального учащегося или учащихся групп, которые учащийся должен в течение учебного года/периода пока учится в филиале",
            unscheduledLessons: "Доп занятия",
            unscheduledLessonsTip: "Дополнительно проведенные занятия помимо занятий в соответствии с расписанием",
            lessonsAttend: "Количество посещенных занятий",
            lessonsAttendTip: "Количество занятий, на которых учащийся присутствовал",
            lessonsCanceled: "Количество отмененных занятий",
            lessonsCanceledTip: "Количество отмененных занятий, которые были отменены филиалом по своей инициативе, например, праздничные дни или индивидуальные занятия, которые были отменены индивидуальным учащимся по своей инициативе заблаговременно в соответствии с условиями договора. Оплата/баланс за данные занятия не списывается.",
            lessonsTotalTip: "Общее количество проведенных занятий с учетом дополнительных и за вычетом отмененных занятий",
            considerDeleted: "С учетом выбывших",
            averagePayment: "Cредний чек",
            lessonCancellation: "Отмена занятий",
            studentReason: "Уважительная причина учащихся",
            sick: "Болезнь учащихся",
            directorsAmount: "Кол-во директоров",
            managersAmount: "Кол-во менеджеров",
            teachersAmount: "Кол-во преподавателей",
            corpAmount: "Кол-во учеников в корп-группах",
            corpHomeAmount: "Кол-во учеников в корп-группах выезд на дом",
            groupAmount: "Кол-во учеников в группах",
            kindergartenAmount: "Кол-во учеников в дет. садах",
            homeAmount: "Кол-во учеников в группах выезд на дом",
            minigroupAmount: "Кол-во учеников в мини-группах"
        },
        requests: {
            dealTransfer: "Перенести в сделки",
            studentTransfer: "Перенести в ученики",
            test: "Тест"
        },
        salary: {
            from: "От",
            to: "До",
            studentsFrom: "Кол-во учеников (от)",
            studentsTo: "Кол-во учеников (до)",
            students: "Количество учеников",
            salary: "Зарплата",
            perLesson: "За урок",
            perStudent: "За ученика",
            studentsError: "Зарплата с диапазоном данного количества учащихся уже была ранее установлена. Рекомендуем изменить текущий создаваемый диапазон или изменить настройку для ранее установленного",
            recalculate: "Пересчитать зарплату",
            zeroLesson: "Только уроки с зарплатой 0",
            recalculated: "Пересчитано уроков"
        },
        tasks: {
            taskDescription: "Суть задачи",
            customer: "Заказчик",
            type: "Тип",
            responsibleCategory: "Категория ответственных сотрудников",
            collaboratorCategory: "Категория соисполнителей",
            collaborators: "Соисполнители",
            burn: "Горит",
            notify: "Напоминание о выполнении",
            every: "Каждый",
            workDay: "Только рабочий",
            repeat: "Повторение",
            bonus: "Бонус",
            editStatus: "Редактировать статус",
            new: "Новая",
            process: "Делаю",
            completed: "Выполнено",
            notCompleted: "Не выполнено",
            addDesk: "Добавить колонку",
            editDesk: "Редактировать колонку",
            move: "Переместить",
            orderSaved: "Порядок сохранен"
        },
        mail: {
            inbox: "Входящие",
            sent: "Отправленные",
            favourite: "Помеченные",
            archived: "Архивированные",
            deleted: "Удаленные",
            read: "Прочитанные",
            notRead: "Непрочитанные",
            reply: "Ответить",
            replyAll: "Ответить всем",
            forward: "Переслать",
            to: "кому",
            addRecipients: "Добавить адресатов",
            new: "Новое сообщение",
            title: "Тема",
            deleteDraft: "Удалить черновик"
        },
        support: {
            openChat: "Открыть чат",
            sent: "Ваше обращение отправлено",
            section: "Раздел",
            type: "Введите сообщение"
        }
    }
}