import {ArrayResponse} from "@/api/response/ArrayResponse";

export class Role {

    static get() {
        return fetch(window.LOCATION_URL + "/roles/index")
            .then(data => data.json())
            .then(data => {
                return new ArrayResponse(data.data)
                    .bind(row => {
                        return {
                            label: row.name,
                            id: row.id,
                            defaultId: row.defaultId
                        };
                    });
            });
    }

    static save(name, id = null, defaultId = 0) {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("defaultId", defaultId);
        if (id && !defaultId) {
            formData.append("id", id);
        }

        return fetch(window.LOCATION_URL + "/roles/save", {
            method: "POST",
            body: formData
        })
            .then(data => data.json());
    }

    static delete(ids) {
        if (!Array.isArray(ids)) {
            ids = [ids];
        }

        const formData = new FormData();
        ids.forEach(id => formData.append("id[]", id));

        return fetch(window.LOCATION_URL + "/roles/delete", {
            method: "POST",
            body: formData
        })
            .then(data => data.json());
    }

}