<template>
  <div class="bottombar">
    <div class="bottombar__bottom-menu bottom-menu">
      <ul class="bottom-menu__list">
        <li class="bottom-menu__item">
          <router-link
            to="/"
            class="bottom-menu__link"
            exact
            active-class="bottom-menu__link--active"
          >
            <div class="bottom-menu__icon">
              <img src="@/assets/img/icons/home.svg" alt="" />
            </div>
              {{ $t("links.main") }}
          </router-link>
        </li>

        <li class="bottom-menu__item">
          <router-link
            to="/accounting"
            class="bottom-menu__link"
            active-class="bottom-menu__link--active"
          >
            <div class="bottom-menu__icon">
              <img src="@/assets/img/icons/documents.svg" alt="" />
            </div>
              {{ $t("links.accounting") }}
          </router-link>
        </li>

        <li class="bottom-menu__item">
          <router-link
            to="/account"
            class="bottom-menu__link"
            active-class="bottom-menu__link--active"
          >
            <div class="bottom-menu__icon">
              <img src="@/assets/img/icons/account.svg" alt="" />
            </div>
              {{ $t("profile.account") }}

<!--            <span class="bottom-menu__notification">-->
<!--                5-->
<!--            </span>-->
          </router-link>
        </li>

        <li class="bottom-menu__item">
          <router-link
            v-if="$route.path != '/mail'"
            to="/mail"
            class="bottom-menu__link"
            active-class="bottom-menu__link--active"
          >
            <div class="bottom-menu__icon">
              <img src="@/assets/img/icons/notifications.svg" alt="" />
            </div>
              {{ $t("links.notifications") }}
          </router-link>

          <a v-else
            class="bottom-menu__link bottom-menu__link--active"
            @click="$store.commit('toggleMailMenu', !mailMenu)"
          >
            <div class="bottom-menu__icon">
              <img src="@/assets/img/icons/notifications.svg" alt="" />
            </div>
              {{ $t("links.notifications") }}
          </a>
        </li>

        <li class="bottom-menu__item">
          <a
            class="bottom-menu__link"
            @click="$store.commit('toggleSidebar', !sidebar)"
          >
            <div class="bottom-menu__icon">
              <img src="@/assets/img/icons/burger.svg" alt="" />
            </div>
              {{ $t("links.menu") }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    sidebar() {
      return this.$store.state.showSidebar;
    },
    mailMenu() {
      return this.$store.state.showMailMenu;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/base";

.bottombar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $dark;
  padding: rem(18px 30px);
  z-index: 1000;

  &__bottom-menu {
    width: 100%;
  }
}

.bottom-menu {
  &__list {
    @include unlist;
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    @include mobile {
      justify-content: space-between;
    }
  }

  &__link {
    color: #fff;
    font-size: rem(11px);
    text-decoration: none;
    text-align: center;
    transition: 250ms;
    text-align: center;
    position: relative;
    display: block;

    &--active {
      transition: 250ms;
      color: $accent;

      .bottom-menu__icon {
        filter: none;
        transition: 250ms;
      }
    }
  }

  &__notification {
    min-width: 18px;
    height: 18px;
    font-size: rem(12px);
    font-weight: 500;
    color: #fff;
    background-color: $red;
    position: absolute;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -10px;
    top: -10px;
  }

  &__icon {
    display: block;
    width: 20px;
    height: 20px;
    margin: auto;
    margin-bottom: rem(8px);
    filter: brightness(0) invert(1);
    transition: 250ms;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>