import {User} from "@/api/users/User";
import {Cookie} from "@/helpers/Cookie";

export class UserHelper {

    static switchUser(userId) {
        User
            .auth(userId)
            .then(data => {
                localStorage.removeItem("permissions");
                localStorage.removeItem("menu");
                localStorage.removeItem("auth");
                localStorage.removeItem("roles");

                const maxAge = {
                    "max-age": 30 * 24 * 60 * 60
                };
                Cookie.setCookie("id", data.id, maxAge);
                Cookie.setCookie("hash", data.hash, maxAge);
                location.href = "/";
            });
    }

    static signOut() {
        Cookie.deleteCookie("id");
        Cookie.deleteCookie("hash");
        location.href = "/";
    }

}