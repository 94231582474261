import {User} from "@/api/users/User";
import {Cookie} from "@/helpers/Cookie";
import {timestampToString} from "@/helpers/Date";

export class UserHelper {

    static switchUser(userId) {
        User
            .auth(userId)
            .then(data => {
                localStorage.removeItem("permissions");
                localStorage.removeItem("menu");
                localStorage.removeItem("auth");
                localStorage.removeItem("roles");

                const maxAge = {
                    "max-age": 30 * 24 * 60 * 60
                };
                Cookie.setCookie("id", data.id, maxAge);
                Cookie.setCookie("hash", data.hash, maxAge);
                location.href = "/";
            });
    }

    static signOut() {
        Cookie.deleteCookie("id");
        Cookie.deleteCookie("hash");
        location.href = "/";
    }

    static mapBalanceHistory(row) {
        let message = "";
        if (row.type === 1) {
            message = `Пополнил баланс на ${row.sum}`;
        } else if (row.type === 2) {
            message = `Оплатил абонемент в группе ${row.group.name}`;
        } else if (row.type === 3) {
            message = `Возврат на баланс на ${row.sum}`;
        } else {
            message = `Списал с баланса ${row.sum}. Комментарий: ${row.comment}`;
        }
        return {
            id: row.id,
            date: timestampToString(row.createdAt),
            time: timestampToString(row.createdAt, true, false),
            name: row.responsible?.name,
            responsibleId: row.responsible?.id,
            groupId: row.responsible?.groupId,
            desc: message,
            img: row.responsible?.avatar ? window.LOCATION_URL + row.responsible.avatar : require("@/assets/img/no-image.png"),
            link: ""
        };
    }

}