<template>
  <div class="form__group form__group--radio">
    <input
      type="radio"
      class="form__control"
      :id="uid"
      :checked="checked"
      :name="name"
      :value="value ? value : label"
      @change="$emit('change', $event.target.value)"
    />
    <label class="form__label form__label--visible" :for="uid">
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'radio'
    },
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    uid() {
      return this._uid;
    },
  },
};
</script>

