<template>
    <li class="sidebar-menu__item">
        <router-link
                class="sidebar-menu__link"
                active-class="sidebar-menu__link--active"
                :to="route"
                :exact="route == '/'"
        >
            <div class="sidebar-menu__link-box">
                <div class="sidebar-menu__label-box">
                    <div class="sidebar-menu__link-icon" v-if="icon">
                        <img :src="icon" :alt="label"/>
                    </div>
                    {{ label }}
                </div>
                <div v-if="notice_count != 0"
                     class="sidebar-menu__notice">
                    {{ notice_count }}
                </div>
            </div>
        </router-link>
    </li>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        route: {
            required: true,
        },
        icon: {
            default: false,
        },
        notice_count: {
            type: Number,
            require: false,
            default: 0
        }
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base";

.sidebar-menu {
  &__notice {
    flex-shrink: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    color: #fff;
    background-color: #F96057;
    border-radius: 50%;
  }

  &__item {
    border-bottom: 1px solid #dfdfdf;
  }

  &__link {
    color: $dark;
    text-decoration: none;
    padding: rem(32px 42px);
    border-left: 6px solid transparent;
    display: flex;
    transition: background-color 250ms, border-color 250ms, color 250ms;

    @include mobile {
      padding: rem(20px 42px);
    }

    &--active {
      border-color: $accent;
      background-color: #043353;
      color: #fff;
      transition: background-color 250ms, border-color 250ms, color 250ms;

      .sidebar-menu__link-icon {
        filter: brightness(0) invert(1);
        transition: 250ms;
      }
    }
  }

  &__link-icon {
    width: 20px;
    height: 20px;
    margin-right: rem(18px);
    transition: 250ms;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__link-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__label-box {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
</style>