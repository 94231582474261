<template>
    <div class="searchbar">
        <div class="searchbar__form form">
            <div class="form__group">
                <label for="search" class="form__label sr-only">{{ $t("search.search") }}</label>
                <input
                        class="form__control"
                        type="text"
                        name="search"
                        id="search"
                        :placeholder="$t('search.search')"
                        @keyup="onKeyUp"
                        v-model="search"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search: ""
        }
    },

    methods: {
        onKeyUp(event) {
            if (event.code.toLowerCase() !== "enter" || this.search.length < 3) {
                return;
            }
            location.href = `/search?q=${this.search}`;
        }
    },

    mounted() {
        if (location.pathname !== "/search") {
            return;
        }

        const params = new URL(location.href).searchParams;
        if (params.get("q")) {
            this.search = params.get("q");
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base";

.form__group {
  margin-bottom: 0;
}

.form__control {
  padding: rem(10px 30px);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.9 20.9'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23D3DDE6;%7D .st1%7Bfill:%230B3352;%7D .st2%7Bfill:%233F454D;%7D .st3%7Bfill:%234274B9;%7D .st4%7Bfill:none;stroke:%234274B9;stroke-width:1.5;stroke-linecap:round;stroke-miterlimit:10;%7D .st5%7Bfill:none;stroke:%234274B9;stroke-width:1.5;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%234274B9;stroke-width:1.5;%7D .st7%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23D3DDE6;%7D .st8%7Bfill:%232BA8E0;%7D .st9%7Bfill:%23FFFFFF;%7D .st10%7Bfill:%23DF4B37;%7D .st11%7Bfill:%233E5C9B;%7D .st12%7Bfill:url(%23Прямоугольник_1188_1_);%7D .st13%7Bfill:%230A68B3;%7D .st14%7Bfill:%23ED8323;%7D .st15%7Bfill:%23ED363F;%7D .st16%7Bfill:%23ED2024;%7D .st17%7Bclip-path:url(%23SVGID_2_);%7D .st18%7Bfill:none;stroke:%23FFFFFF;stroke-width:2;%7D %3C/style%3E%3Cswitch%3E%3CforeignObject requiredExtensions='http://ns.adobe.com/AdobeIllustrator/10.0/' width='1' height='1'/%3E%3Cpath class='st0' d='M20.6 18l-4-4c2.8-4.2 1.6-9.8-2.6-12.6C9.9-1.3 4.3-0.1 1.5 4.1c-2.8 4.2-1.6 9.8 2.6 12.6 3 2 7 2 10 0l3.9 4c0.4 0.4 0.9 0.4 1.3 0l0 0 1.3-1.3C20.9 19 20.9 18.4 20.6 18 20.6 18 20.6 18 20.6 18zM1.8 9.1c0-4 3.2-7.2 7.2-7.2s7.3 3.2 7.3 7.2 -3.2 7.2-7.2 7.2l0 0C5.1 16.3 1.8 13.1 1.8 9.1z'/%3E%3C/switch%3E%3C/svg%3E");
  background-size: rem(20px 20px);
  background-repeat: no-repeat;
  background-position: calc(100% - 8px);
}
</style>