<template>
    <div class="form__group">
        <label
                :for="uid"
                class="form__label"
                :class="visibleLabel ? 'form__label--visible' : null"
        >
            {{ label }}
        </label>
        <multiselect
                :class="[
                    !label.length ? 'multiselect--no-label' : null,
                    showInput ? 'multiselect--imput-show' : null,
                    bigSelect ? 'multiselect--big' : null,
                ]"
                :id="uid"
                :value="val"
                :options="options"
                :searchable="searchable"
                label="label"
                track-by="label"
                :disabled="disabled"
                :placeholder="label"
                :multiple="multiple"
                :preselect-first="preselect"
                :allowEmpty="allowEmpty"
                :isUserText="isUserText"
                :userTextLabel="userTextLabel"
                :close-on-select="!multiple"
                @input="changeVal($event)"
                @open="typeMoveSelect('open')"
                @close="typeMoveSelect('close')"
                ref="select"
                :maxHeight="500"
        >
            <template slot="selection" slot-scope="{ values }">
        <span
                class="multiselect__single multiselect__single--multiple"
                v-if="values.length > maxVisible"
        >{{ values.length }} {{ $t("common.selected") }}</span
        >
            </template>
        </multiselect>
        <Input v-if="showInput"
               label="Введите вариант ответа"
               :visibleLabel="false"/>
    </div>
</template>

<script>
import Multiselect from "@/components/elements/vue-multiselect/Multiselect";
import Input from "@/components/elements/form/Input.vue";

export default {
    components: {
        Multiselect,
        Input
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        visibleLabel: {
            type: Boolean,
            default: true,
        },
        // Используется ли режим добавления пользователем варината ответа
        isUserText: {
            type: Boolean,
            default: false,
        },
        // Лейбл для варианта пользователя
        userTextLabel: {
            type: String,
            default: 'Другое'
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        preselect: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [Array, Object],
            default: null,
        },
        searchable: {
            type: Boolean,
            default: false
        },
        maxVisible: {
            type: Number,
            default: 3
        },
        bigSelect: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            val: "",
            showInput: false
        };
    },
    created() {
        this.val = this.value;
    },
    methods: {
        changeVal(event) {
            this.val = event;
            this.$emit("change", event);
            if (this.isUserText && this.val.isUserText) {
                this.showInput = true;
            } else {
                this.showInput = false;
            }
        },
        typeMoveSelect(val) {
            this.$emit('type-move-select', val)
        }
    },
    computed: {
        uid() {
            return this._uid;
        },
    },

    watch: {
        value(value) {
            this.val = value;
        }
    }
};
</script>

<style lang="scss">
.multiselect__option {
  &--selected {
    background: #f7f7f7;
  }
}
</style>