<template>
    <li class="header-menu__item">
        <router-link
                :to="route"
                active-class="header-menu__link--active"
                class="header-menu__link"
        >
            {{ label }}
        </router-link>
        <div v-if="notice_count != 0"
             class="header-menu__notice">
            {{ notice_count }}
        </div>
    </li>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        route: {
            required: true,
        },
        // Кол-во уведомлений
        notice_count: {
            type: Number,
            require: false,
            default: 0
        }
    },
};
</script>


<style lang="scss">
@import "@/assets/scss/base";
.header-menu {
  &__notice {
    position: absolute;
    left: 50%;
    top: calc(100% - 2px);
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(24px);
    height: rem(24px);
    color: #fff;
    background-color: #F96057;
    border-radius: 50%;
  }
  &__list {
    @include unlist;
    display: flex;
    align-items: center;
    @include mobile {
      overflow: scroll;
      padding-bottom: rem(50px);
    }
  }
  &__item {
    position: relative;
    margin: rem(0 17.5px);
    // margin-top: rem(20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    @include lg {
      margin: rem(0 8px);
    }
    @include tablet {
      margin: rem(0 7px);
    }
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__link {
    margin-bottom: rem(5px);
    color: #d3dde6;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    &--active {
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -34px;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $accent;
      }
    }
    @include mobile {
      font-size: rem(8px);
    }
  }
}
</style>