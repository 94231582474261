export class ConfirmPromise {

    #yesCallback = () => "";
    #noCallback = () => "";

    yes(func) {
        this.#yesCallback = func;

        return this;
    }


    no(func) {
        this.#noCallback = func;

        return this;
    }

    complete(result = true) {
        if (result) {
            this.#yesCallback();
        } else {
            this.#noCallback();
        }

        return this;
    }

}