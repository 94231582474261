/**
 * @template T
 */
export class ArrayResponse {

    #handle = row => row;

    /**
     *
     * @param data {Array}
     * @param count {number}
     */
    constructor(data, count = -1) {
        this.data = data;
        this.count = count;
    }

    /**
     *
     * @param func {function(row: Object)}
     * @return ArrayResponse
     */
    bind(func) {
        this.#handle = func;

        return this;
    }

    /**
     *
     * @returns {T[]}
     */
    toArray() {
        return this.data.map(row => this.#handle(row));
    }

}